<template>
  <div class="pb-5">
    <gat-form
      ref="vesselForm"
      :readonly="!applicationStore.userRight('VE.EDIT_AND_NEW')"
      class="pb-6"
      v-model="formValid"
      :isLoading="isLoading">
      <GatGroup class="pa-0" title="Vessel details" :collapsed="isNew ? !expandedIfNew : $vuetify.breakpoint.xsOnly">
        <v-layout wrap v-if="isVesselNameInUse || isVesselImoInUse">
          <v-alert type="warning" outlined dense class="ml-2 pr-6 errormsg">
            <div v-if="isVesselImoInUse" :class="isVesselNameInUse ? 'mb-5' : ''">
              {{ vesselImoErrorDescription }}
              <ol>
                <li v-for="(imo, index) in duplicateVesselIMO" :key="index">
                  <router-link :to="'/vessel/' + imo.ID">{{ imo.NAME }}</router-link>
                </li>
              </ol>
            </div>
            <div v-if="isVesselNameInUse">
              {{ vesselNameErrorDescription }}
              <ol>
                <li v-for="(vessel, index) in duplicateVesselNames" :key="index">
                  <router-link :to="'/vessel/' + vessel.ID">{{
                    vessel.NAME + ' (IMO: ' + vessel.LLOYDSNR + ')'
                  }}</router-link>
                </li>
              </ol>
            </div>
          </v-alert>
        </v-layout>
        <template slot="compact">
          <v-layout wrap>
            <gat-compact-field :value="vessel.VES_INACTIVE" checkbox label="Inactive" />
            <gat-compact-field :value="vessel.VESSEL_PREFIX + ' ' + vessel.NAME" label="Vessel name" />
            <gat-compact-field :value="vessel.CALLSIGN" label="Call sign" />
            <gat-compact-field :value="vesselTypeName" label="Vessel type" />
            <gat-compact-field :value="vessel.LLOYDSNR" label="IMO" />
            <show-vesseltracker-info :ImoNumber="vessel.LLOYDSNR" />
          </v-layout>
          <gat-dyna-fields
            componentName="tsUserDefData.VesselDyna"
            container="Upper part of window"
            v-model="vessel.dynaValues"
            :compact="true"
            v-if="applicationStore.user.internal"></gat-dyna-fields>
        </template>
        <v-layout wrap>
          <GatSubGroup title="" size="xs12 sm10">
            <gat-edit
              v-model="vessel.VESSEL_PREFIX"
              label="Prefix"
              size="xs3 sm3 md2 lg2 xl1"
              :maxChars="applicationStore.getColumnLength('Vessel.VESSEL_PREFIX')">
            </gat-edit>
            <gat-edit
              v-model="vessel.NAME"
              :autofocus="value && value.ID == 0"
              label="Vessel name"
              size="xs9 sm8 md4 xl3"
              :required="true"
              @blur="checkDuplicateVesselName"
              :hint="duplicateVesselName"
              :maxChars="applicationStore.getColumnLength('Vessel.NAME')">
            </gat-edit>
            <gat-edit
              v-model="vessel.CALLSIGN"
              label="Call sign"
              size="xs4 sm3 md2 xl2"
              :required="true"
              :maxChars="applicationStore.getColumnLength('Vessel.CALLSIGN')">
            </gat-edit>
            <gat-edit
              v-model="vessel.LLOYDSNR"
              label="IMO no"
              size="xs8 sm8 md4 xl2"
              @input="checkDuplicateVesselImo"
              :required="true"
              :validatorRules="[validateImo]"
              :counter="7"
              :maxChars="applicationStore.getColumnLength('Vessel.LLOYDSNR')">
            </gat-edit>
            <gat-select
              v-model="vessel.VESSEL_TYPE_CODE"
              label="Vessel type"
              size="xs12 sm6 md6 xl3"
              :items="helperStore.vesselTypes"
              :required="this.applicationStore.globalSettings.SGL_VESSEL_MAN_VESSELTYPE == 1">
            </gat-select>
            <show-vesseltracker-info :ImoNumber="vessel.LLOYDSNR" />
            <gat-edit
              v-model="vessel.VES_INACTIVE"
              label="Inactive"
              size="xs3 sm6 md6 xl12"
              :uncheckedValue="0"
              :checkedValue="1"
              checkbox>
            </gat-edit>
            <gat-dyna-fields
              componentName="tsUserDefData.VesselDyna"
              container="Upper part of window"
              v-model="vessel.dynaValues"
              v-if="applicationStore.user.internal"></gat-dyna-fields>
          </GatSubGroup>
          <GatSubGroup v-if="!this.$vuetify.breakpoint.xs" title="" size="xs12 sm2" class="mb-1 vesselImg">
            <v-img
              :src="'https://webservice.vesseltracker.com/shipPhotoServlet?res=full&imo=' + vessel.LLOYDSNR"
              max-height="250px"
              contain
              class="vesselImg"></v-img>
          </GatSubGroup>
        </v-layout>
      </GatGroup>
      <VesselNetSearch
        v-if="!vessel.ID && importedFrom != vessel.LLOYDSNR && !fromimport"
        v-show="noOfVesselFoundOnline > 0"
        :vessel-name="vessel.NAME"
        :call-sign="vessel.CALLSIGN"
        :IMO="vessel.LLOYDSNR"
        @vessel-import="vesselImport"
        v-model="noOfVesselFoundOnline"
        emp="true"></VesselNetSearch>
      <!-- :searchText="vessel.NAME" -->

      <GatGroup class="pa-0" title="Registration info" :collapsed="isNew ? !expandedIfNew : !(value && value.ID == 0)">
        <template slot="compact">
          <v-layout wrap>
            <gat-compact-field v-model="vessel.NATIONAL_CODES_ID" label="Flag" />
            <gat-compact-field v-model="vessel.MMSI" label="MMSI" />
            <gat-compact-field v-model="vessel.ISPS_SERTIFICATE" label="ISPS no" />
            <gat-compact-field v-model="vessel.TONNAGE_SERTIFICATE" label="ton cert" />
            <gat-compact-field v-model="vessel.CLASS" label="Class" />

            <gat-compact-field v-model="vessel.HOMEPORT" label="Homeport" />
            <gat-compact-field v-model="vessel.REG_PLACE" label="Reg place" />
            <gat-compact-field v-model="vessel.CONSTRUCTION_YEAR" label="Const. year" />
            <gat-compact-field v-model="vessel.REG_YEAR" label="Reg year" />
            <gat-compact-field v-model="vessel.ICE_CLASS" label="Ice class" />
            <gat-compact-field v-model="vessel.CREW" label="Crew" />
            <gat-compact-field v-model="vessel.PASSENGERS" label="PAX cap" />
            <gat-compact-field v-model="vessel.CSI" label="CSI" />
            <gat-compact-field v-model="vessel.ESI" label="ESI" />
            <gat-compact-field v-model="vessel.TRADING_CERT" label="FTC" :isDate="true" />
          </v-layout>
        </template>
        <v-layout wrap>
          <GatSubGroup title="" size="xs12">
            <gat-select
              v-model="vessel.NATIONAL_CODES_ID"
              label="Flag"
              size="s"
              :items="helperStore.countries"
              :required="this.applicationStore.globalSettings.SGL_VESSEL_MAN_FLAG == 1">
            </gat-select>
            <gat-edit
              v-model="vessel.MMSI"
              label="MMSI"
              size="s"
              hint="Marine Mobile Service Identity"
              dataType="number">
            </gat-edit>
            <gat-edit
              v-model="vessel.ISPS_SERTIFICATE"
              label="ISPS no"
              size="s"
              hint="International Ship and Port Facility Security Code"
              :maxChars="applicationStore.getColumnLength('Vessel.ISPS_SERTIFICATE')">
            </gat-edit>
            <gat-edit
              v-model="vessel.TONNAGE_CERTIFICATE"
              label="Ton. certificate"
              size="s"
              hint="Tonnage certificate"
              :maxChars="applicationStore.getColumnLength('Vessel.TONNAGE_CERTIFICATE')">
            </gat-edit>
            <gat-edit
              v-model="vessel.CLASS"
              label="Class"
              size="s"
              hint=""
              :maxChars="applicationStore.getColumnLength('Vessel.CLASS')">
            </gat-edit>
            <gat-edit
              v-model="vessel.HOMEPORT"
              label="Homeport"
              size="s"
              hint=""
              :required="this.applicationStore.globalSettings.SGL_VESSEL_MAN_HOMEPORT == 1"
              :maxChars="applicationStore.getColumnLength('Vessel.HOMEPORT')">
            </gat-edit>
            <gat-edit
              v-model="vessel.REG_PLACE"
              label="Reg. place"
              size="s"
              hint="Registration place"
              :maxChars="applicationStore.getColumnLength('Vessel.REG_PLACE')">
            </gat-edit>
            <gat-edit
              v-model="vessel.CONSTRUCTION_YEAR"
              label="Const. year"
              size="s"
              hint="Construction year"
              dataType="number"
              noThousandSeperator />
            <gat-edit
              v-model="vessel.REG_YEAR"
              label="Reg. year"
              size="s"
              hint="Registration year"
              dataType="number"
              noThousandSeperator />
            <gat-edit
              v-model="vessel.ICE_CLASS"
              label="Ice class"
              size="s"
              hint=""
              :maxChars="applicationStore.getColumnLength('Vessel.ICE_CLASS')">
            </gat-edit>
            <gat-edit v-model="vessel.CREW" label="Crew" size="s" hint="" dataType="number"> </gat-edit>
            <gat-edit
              v-model="vessel.PASSENGERS"
              label="PAX cap"
              size="s"
              hint="Passenger capacity of vessel"
              dataType="number">
            </gat-edit>
            <gat-edit v-model="vessel.CSI" label="CSI" size="s" hint="Clean Shipping Index" dataType="number">
            </gat-edit>
            <gat-edit v-model="vessel.ESI" label="ESI" size="s" hint="Environmental Ship Index" dataType="number">
            </gat-edit>
            <gat-edit
              v-model="vessel.TRADING_CERT"
              label="FTC"
              size="s"
              hint="Foreign Trade Certificate issued date"
              dateEdit>
            </gat-edit>
          </GatSubGroup>
        </v-layout>
      </GatGroup>

      <GatGroup class="pa-0" title="Particulars" :collapsed="isNew ? !expandedIfNew : !(value && value.ID == 0)">
        <template slot="compact">
          <v-layout wrap>
            <gat-compact-field v-model="vessel.DWT" label="DWT" size="xs" />
            <gat-compact-field v-model="vessel.GT" label="GT" size="xs" />
            <gat-compact-field v-model="vessel.NT" label="NT" size="xs" />
            <gat-compact-field v-model="vessel.CGT" label="RGT" size="xs" />
            <gat-compact-field v-model="vessel.SBT" label="SBT" size="xs" />
            <gat-compact-field v-model="vessel.CBMTANK" label="CBM tank" size="xs" />
            <gat-compact-field v-model="vessel.LOA" label="LOA" size="xs" />
            <gat-compact-field v-model="vessel.BEAM" label="Beam" size="xs" />
            <gat-compact-field v-model="vessel.SUMMERDRAFT" label="Max draft" size="xs" />
            <gat-compact-field v-model="vessel.AIR_DRAFT" label="Air draft" size="xs" />
            <gat-compact-field v-model="vessel.LL" label="LL" size="xs" />
            <gat-compact-field v-model="vessel.KNOTS" label="Knots" size="xs" />
            <gat-compact-field v-if="udfCaption1" v-model="vessel.USER_VALUE" :label="udfCaption1" size="xs" />
            <gat-compact-field v-if="udfCaption2" v-model="vessel.USER_TEXT_VALUE" :label="udfCaption2" size="xs" />
          </v-layout>
        </template>
        <v-layout wrap>
          <GatSubGroup title="" size="xs12">
            <gat-edit
              v-model="vessel.DWT"
              label="DWT"
              size="s"
              hint="Deadweigth Tonnage"
              dataType="number"
              :required="this.applicationStore.globalSettings.SGL_VESSEL_MAN_DWT == 1">
            </gat-edit>
            <gat-edit
              v-model="vessel.GT"
              label="GT"
              size="s"
              hint="Gross Register Tonnage(GRT)"
              dataType="number"
              :required="this.applicationStore.globalSettings.SGL_VESSEL_MAN_GT == 1">
            </gat-edit>
            <gat-edit v-model="vessel.NT" label="NT" size="s" hint="Net Register Tonnage(NRT)" dataType="number">
            </gat-edit>
            <gat-edit
              v-model="vessel.CGT"
              label="RGT"
              size="s"
              hint="Reduced Gross Tonnage (GT-SBT)"
              dataType="number"
              :required="this.applicationStore.globalSettings.SGL_VESSEL_MAN_RGT == 1">
            </gat-edit>
            <gat-edit
              v-model="vessel.SBT"
              label="SBT"
              size="s"
              hint="Segregated Ballast Tanks"
              dataType="number"
              :required="this.applicationStore.globalSettings.SGL_VESSEL_MAN_SBT == 1">
            </gat-edit>
            <gat-edit v-model="vessel.CBMTANK" label="CBM tank" size="s" hint="CuBic Meter tank" dataType="number">
            </gat-edit>
            <gat-edit
              v-model="vessel.LOA"
              label="LOA"
              size="s"
              hint="Lengt Over All"
              dataType="number"
              :required="this.applicationStore.globalSettings.SGL_VESSEL_MAN_LOA == 1">
            </gat-edit>
            <gat-edit
              v-model="vessel.BEAM"
              label="Beam"
              size="s"
              hint=""
              dataType="number"
              :required="this.applicationStore.globalSettings.SGL_VESSEL_MAN_BEAM == 1">
            </gat-edit>
            <gat-edit v-model="vessel.SUMMERDRAFT" label="Max draft" size="s" hint="" dataType="number"> </gat-edit>
            <gat-edit v-model="vessel.AIR_DRAFT" label="Air draft" size="s" hint="" dataType="number"> </gat-edit>
            <gat-edit v-model="vessel.LL" label="LL" size="s" hint="London Length" dataType="number"> </gat-edit>
            <gat-edit v-model="vessel.KNOTS" label="Knots" size="s" hint="" dataType="number"> </gat-edit>
            <gat-edit
              :value="(vessel.BEAM + vessel.LOA) * vessel.SUMMERDRAFT"
              label="G-factor"
              size="s"
              hint=""
              dataType="number"
              :readonly="true"
              :decimals="2">
            </gat-edit>
            <gat-edit
              v-if="udfCaption1"
              v-model="vessel.USER_VALUE"
              :label="udfCaption1"
              size="s"
              hint=""
              dataType="number">
            </gat-edit>
            <gat-edit
              v-if="udfCaption2"
              v-model="vessel.USER_TEXT_VALUE"
              :label="udfCaption2"
              size="s"
              hint=""
              :maxChars="applicationStore.getColumnLength('Vessel.USER_TEXT_VALUE')">
            </gat-edit>
          </GatSubGroup>
        </v-layout>
      </GatGroup>
      <GatGroup
        class="pa-0"
        title="Clients connected to vessel"
        :collapsed="isNew ? !expandedIfNew : true"
        v-if="applicationStore.user.internal">
        <template slot="compact">
          <v-layout wrap>
            <template v-for="(client, index) in vessel.clients">
              <gat-compact-field
                :value="client.ClientName"
                :linkUrl="'/client/' + client.VHO_CLIENT_ID"
                :label="getCode(client.VHO_ROLE_ID)"
                size="m"
                :key="index" />
            </template>
          </v-layout>
        </template>
        <div v-if="vessel.ID">
          <v-layout wrap>
            <gat-grid
              :items="vessel.clients"
              :columns="clientColumns"
              class="elevation-0"
              @row-clicked="clientRowClicked"
              rowHint="click to edit">
              <template slot="cell" slot-scope="props">
                <ClientCreditFlag
                  v-if="props.column.field == 'CLIENT_CREDIT_FLAG'"
                  :creditBalance="+props.row.CLIENT_CREDIT_BALANCE"
                  :flagValue="+props.value" />
              </template>
            </gat-grid>
          </v-layout>
        </div>
        <v-btn
          v-if="vessel.ID"
          slot="btn"
          class="addClient"
          outlined
          color="primary"
          small
          @click="addClient"
          :disabled="!applicationStore.userRight('VE.EDIT_AND_NEW')">
          <v-icon small>mdi-plus</v-icon>Add
        </v-btn>
        <div v-else class="pa-3">The new vessel have to be saved before this section can be edited</div>
      </GatGroup>

      <GatGroup class="pa-0" title="Remarks" :collapsed="false" v-if="applicationStore.user.internal">
        <template slot="compact">
          <v-layout class="pr-3" wrap>
            <gat-compact-field
              v-model="vessel.HOLD_HATCH_DESC"
              label="Hold hatch description"
              :size="splitSize([vessel.COMMENTS, vessel.WARNING, vessel.HOLD_HATCH_DESC, vessel.CRANES], 4)" />
            <gat-compact-field
              :value="vessel.CRANES"
              label="Crane description"
              :size="splitSize([vessel.COMMENTS, vessel.WARNING, vessel.HOLD_HATCH_DESC, vessel.CRANES], 4)" />
            <gat-compact-field
              v-model="vessel.COMMENTS"
              label="Other comments"
              :size="splitSize([vessel.COMMENTS, vessel.WARNING, vessel.HOLD_HATCH_DESC, vessel.CRANES], 4)" />
          </v-layout>
        </template>
        <v-layout wrap>
          <GatSubGroup title="" size="xs12">
            <gat-edit v-model="vessel.HOLD_HATCH_DESC" label="Hold hatch description" size="l" :rows="5" :maxChars="0">
            </gat-edit>
            <gat-edit v-model="vessel.CRANES" label="Crane description" size="l" :rows="5" :maxChars="0"> </gat-edit>
            <gat-edit v-model="vessel.COMMENTS" label="Other comments" size="l" :rows="5" :maxChars="0"> </gat-edit>
          </GatSubGroup>
        </v-layout>
      </GatGroup>

      <GatGroup class="pa-0" title="Alert" :collapsed="false" v-if="applicationStore.user.internal">
        <template slot="compact">
          <v-layout class="pr-3" wrap>
            <gat-compact-field
              v-if="vessel.SHOW_WARNING"
              v-model="vessel.WARNING"
              label="Alert message"
              prepend-icon="notification_important"
              icon-color="orange"
              size="xxl" />
          </v-layout>
        </template>
        <v-layout wrap>
          <GatSubGroup title="" size="xs12">
            <gat-edit
              v-model="vessel.SHOW_WARNING"
              label="Show an alert when this vessel is used"
              size="xs12"
              :uncheckedValue="0 || null"
              :checkedValue="1"
              checkbox>
            </gat-edit>
            <gat-edit
              v-model="vessel.WARNING"
              label="Alert message"
              size="xs12"
              :maxChars="0"
              :disabled="vessel.SHOW_WARNING == 0 || vessel.SHOW_WARNING == null"
              :rows="5">
            </gat-edit>
          </GatSubGroup>
        </v-layout>
      </GatGroup>

      <GatGroup
        class="pa-0"
        title="Our data"
        :collapsed="isNew ? !expandedIfNew : true"
        v-if="applicationStore.user.internal">
        <template slot="compact">
          <gat-dyna-fields
            v-model="vessel.dynaValues"
            componentName="tsUserDefData.VesselDyna"
            container="Tab page"
            :compact="true"></gat-dyna-fields>
        </template>
        <gat-dyna-fields
          v-model="vessel.dynaValues"
          componentName="tsUserDefData.VesselDyna"
          container="Tab page"></gat-dyna-fields>
      </GatGroup>

      <GatGroup class="pa-0" title="Communication" :collapsed="isNew ? !expandedIfNew : true">
        <template slot="compact">
          <v-layout wrap>
            <template v-for="(com, index) in vessel.communication">
              <gat-compact-field :value="com.DETAILS" :label="com.PHONE_NAME" size="s" :key="index" />
            </template>
          </v-layout>
        </template>
        <div v-if="vessel.ID">
          <v-layout wrap>
            <gat-grid
              :items="vessel.communication"
              :columns="communicationColumns"
              class="elevation-0"
              @row-clicked="comRowClicked"
              rowHint="click to edit">
            </gat-grid>
          </v-layout>
        </div>
        <v-btn
          v-if="vessel.ID"
          slot="btn"
          class="addClient"
          outlined
          color="primary"
          small
          @click="addCommunication"
          :disabled="!applicationStore.userRight('VE.EDIT_AND_NEW')">
          <v-icon small>mdi-plus</v-icon>Add
        </v-btn>
        <div v-else class="pa-3">The new vessel have to be saved before this section can be edited</div>
      </GatGroup>
    </gat-form>
    <gat-bottom-panel
      :orgValue="orgVessel"
      :newValue="vessel"
      itemDescription="this vessel"
      :saveBtn="true"
      :deleteBtn="true"
      :buttonsEnabled="!saving"
      :saveDisabled="!applicationStore.userRight('VE.EDIT_AND_NEW')"
      :deleteDisabled="!vessel.ID || !applicationStore.userRight('VE.DELETE')"
      @saveClicked="saveClicked"
      @deleteClicked="deleteClicked"
      @leftWithoutSaving="leftWithoutSaving"
      :saveBtnText="getSaveText"></gat-bottom-panel>

    <vessel-client-role
      :showForm="showRoleForm"
      v-model="currentRole"
      @close="roleFormClosed"
      :readonly="!applicationStore.userRight('VE.EDIT_AND_NEW')"></vessel-client-role>
    <vessel-comunication
      :showForm="showComForm"
      v-model="currentCom"
      @close="comFormClosed"
      :readonly="!applicationStore.userRight('VE.EDIT_AND_NEW')"></vessel-comunication>
  </div>
</template>

<script>
import GatUtils from '@/app-components/GatUtils';
import GatDynaFields from '@/app-components/dynafields/GatDynaFields.vue';
import ClientCreditFlag from '@/app-components/client/ClientCreditFlag.vue';
// eslint-disable-next-line import/no-cycle
import { apiPut, apiDelete, apiGet } from '@/store/api';
import ShowVesseltrackerInfo from '@/app-components/ShowVesseltrackerInfo.vue';
import { useToolbarStore } from '@/store/toolbarStore';
import { useApplicationStore } from '@/store/applicationStore';
import { useHelperStore } from '@/store/helperStore';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import VesselClientRole from './VesselClientRole.vue';
import VesselComunication from './VesselComunication.vue';
import VesselNetSearch from './VesselNetSearch.vue';
import SednaApiService from '../../services/api/external/sednaAPI';

export default {
  name: 'VesselDetails',
  props: {
    value: [Object, Array],
    fromimport: Boolean,
    isNew: Boolean,
  },
  components: {
    GatDynaFields,
    VesselClientRole,
    VesselComunication,
    VesselNetSearch,
    ClientCreditFlag /* VesselDialog */,
    ShowVesseltrackerInfo,
  },
  setup() {
    const toolbarStore = useToolbarStore();
    const applicationStore = useApplicationStore();
    const helperStore = useHelperStore();
    return {
      helperStore,
      applicationStore,
      toolbarStore,
    };
  },
  data() {
    return {
      expandedIfNew: false, // is set in activated/deactivated based on isNew prop.
      vessel: {},
      orgVessel: {},
      isVesselNameInUse: false,
      isVesselImoInUse: false,
      duplicateVesselNames: {},
      duplicateVesselIMO: {},
      saving: false,
      noOfVesselFoundOnline: 0,
      formValid: false,
      importedFrom: '0',
      showRoleForm: false,
      showComForm: false,
      currentRole: {},
      currentCom: {},
      clientColumns: [
        {
          header: 'Role',
          calculated: (rowData) =>
            GlobalHelperFunctions.lookupCode(rowData.VHO_ROLE_ID, this.helperStore.clientTypes, 'ID', 'NAME'),
        },
        { header: 'Client', linkUrl: '/client/field[VHO_CLIENT_ID]', linkText: 'field[ClientName]' },
        { header: '', field: 'CLIENT_CREDIT_FLAG', width: '1%' },
        { header: 'Restrict to role', field: 'VHO_RESTRICT_CURRENT_ROLE', checkbox: true, hideOnPhone: false },
        { header: 'Copy to call', field: 'VHO_DEFAULT_PORTCALL_HOTLIST', checkbox: true, hideOnPhone: false },
      ],
      communicationColumns: [
        { header: 'Name', field: 'PHONE_NAME' },
        { header: 'Type', field: 'PHONE_TYPE' },
        { header: 'Number', field: 'DETAILS' },
      ],
      isLoading: false,
    };
  },
  created() {
    window.messageBus.$on('toolbar_prev_clicked:vesselDetails', () => {
      if (this.traversableVesselList) {
        let idx = this.traversableVesselList.findIndex((item) => item.ID == this.vessel.ID);
        if (idx >= 0) {
          idx -= 1;
          if (idx < 0) {
            idx = this.traversableVesselList.length - 1;
          }
          const newVesselId = this.traversableVesselList[idx].ID;
          this.$router.push(`/vessel/${newVesselId}`);
        }
      }
    });
    window.messageBus.$on('toolbar_next_clicked:vesselDetails', () => {
      if (this.traversableVesselList) {
        let idx = this.traversableVesselList.findIndex((item) => item.ID == this.vessel.ID);

        if (idx >= 0) {
          idx += 1;
          if (idx >= this.traversableVesselList.length) {
            idx = 0;
          }

          const newVesselId = this.traversableVesselList[idx].ID;
          this.$router.push(`/vessel/${newVesselId}`);
        }
      }
    });
  },

  mounted() {
    this.vessel = GatUtils.makeCopyViaJson(this.value);
    if (!this.vessel.dynaValues) {
      this.vessel.dynaValues = [];
    }
    if (this.fromimport) this.orgVessel = {};
    else this.orgVessel = GatUtils.makeCopyViaJson(this.vessel);
  },

  activated() {
    if (this.traversableVesselList.length > 1 && !this.fromimport && this.$route.name != 'VesselNew') {
      this.toolbarStore.setToolbarNavBtns({ name: 'vesselDetails', show: true });
      this.applicationStore.setTraversableListCounter(true);
    }
    this.expandedIfNew = this.isNew;
  },

  deactivated() {
    this.isVesselNameInUse = false;
    this.isVesselImoInUse = false;
    this.duplicateVesselNames = {};
    this.duplicateVesselIMO = {};
    this.noOfVesselFoundOnline = 0;
    // console.log(this.$route.name.from);
    if (this.$route.name != 'vesselHistory' && this.$route.name != 'vesselDocs') {
      this.applicationStore.setTraversableList([]);
      this.toolbarStore.setToolbarNavBtns({ name: 'vesselDetails', show: false });
      this.applicationStore.setTraversableListIndex(0);
      this.applicationStore.setTraversableListCounter(false);
    }
    this.expandedIfNew = !this.isNew;
  },

  computed: {
    // Can be a string or empty.
    udfCaption1() {
      return this.agencySettings.VESSEL_UDF_CAPTION_1;
    },
    // Can be a string or empty.
    udfCaption2() {
      return this.agencySettings.VESSEL_UDF_CAPTION_2;
    },
    selectedAgency() {
      return this.applicationStore.agencyIdSelected;
    },
    agencySettings() {
      return this.applicationStore.getAgencySettings(this.selectedAgency);
    },
    countryName() {
      return GlobalHelperFunctions.lookupCode(this.vessel.NATIONAL_CODES_ID, this.helperStore.countries);
    },

    duplicateVesselName() {
      if (this.isVesselNameInUse) {
        return 'A vessel with the same name already exists';
      }
      return '';
    },

    getSaveText() {
      if (this.fromimport) return 'Import';
      return 'Save';
    },

    validateImo() {
      const imo = this.vessel.LLOYDSNR;

      if (!imo || imo.length != 7) {
        return 'IMO number must be 7 digits';
      }
      if (imo.length == 7 && imo == '0000000') {
        return true;
      }
      // IMO VALIDATION
      const arr = this.vessel.LLOYDSNR.split('');
      const checksum = this.vessel.LLOYDSNR.charAt(6);
      let sum = 0;
      for (let i = 0; i < 6; i++) {
        // eslint-disable-next-line no-new-wrappers
        const n = new Number(arr[i]);
        sum += n * (7 - i);
      }
      if (checksum != sum % 10) {
        return 'Invalid IMO number.';
      }

      if (!this.isVesselImoInUse) {
        return true;
      }
      return 'duplicate IMO number';
    },

    vesselTypeName() {
      return GlobalHelperFunctions.lookupCode(this.vessel.VESSEL_TYPE_CODE, this.helperStore.vesselTypes);
    },

    vesselNameErrorDescription() {
      const vessel = this.duplicateVesselNames;
      if (vessel.length > 1) {
        return 'Vessels with the same name already exists:';
      }
      return 'A Vessel with the same name already exists:';
    },

    vesselImoErrorDescription() {
      const vessel = this.duplicateVesselIMO;
      if (vessel.length > 1) {
        return 'Vessels with the same IMO no already exists:';
      }
      return 'A Vessel with the same IMO no already exists:';
    },

    vesselId() {
      return this.vessel.ID;
    },

    traversableVesselList() {
      return this.applicationStore.traversableList.items;
    },
  },

  watch: {
    value(newValue) {
      this.vessel = GatUtils.makeCopyViaJson(newValue);
      if (this.fromimport) this.orgVessel = {};
      else this.orgVessel = GatUtils.makeCopyViaJson(this.vessel);
    },
    vesselId() {
      this.isVesselNameInUse = false;
      this.isVesselImoInUse = false;
      this.duplicateVesselNames = {};
      this.duplicateVesselIMO = {};
      this.noOfVesselFoundOnline = 0;
      // Method uses "this.vessel" to check for duplicates. setTimeout is set in order to wait for this.vessel to be populated before check.
      setTimeout(() => {
        this.checkDuplicateVesselName();
        this.checkDuplicateVesselImo();
      }, 500);

      const idx = this.traversableVesselList.findIndex((item) => item.ID == this.vessel.ID);
      this.applicationStore.setTraversableListIndex(idx);
    },
  },

  methods: {
    splitSize(fields, maxHorizontal) {
      return GlobalHelperFunctions.splitSize(fields, maxHorizontal);
    },
    addClient() {
      this.currentRole = { VHO_VESSEL_ID: this.vessel.ID };
      this.showRoleForm = true;
    },

    addCommunication() {
      this.currentCom = { VESSEL_ID: this.vessel.ID };
      this.showComForm = true;
    },

    clientRowClicked(rowData) {
      this.currentRole = rowData;
      this.showRoleForm = true;
    },

    comFormClosed(params) {
      this.showComForm = false;
      if (params.btn == 'save') {
        apiPut('vesselcommunication', { value: params.value, orgValue: this.currentCom }).then((result) => {
          if (result.resultCategory == 1) {
            this.vessel.communication = result.payload;
            this.orgVessel.communication = this.vessel.communication; // to avoid save btn "changes"
          }
        });
      } else if (params.btn == 'delete') {
        apiDelete(`vesselcommunication/${params.value.ID}`).then(() => {
          const idx = this.vessel.communication.findIndex((item) => item.ID == params.value.ID);
          this.currentCom = params.value;
          this.vessel.communication.splice(idx, 1);
          this.orgVessel.communication = this.vessel.communication; // to avoid save btn "changes"
        });
      }
      this.currentRole = {};
    },

    comRowClicked(rowData) {
      this.currentCom = rowData;
      this.showComForm = true;
    },

    deleteClicked() {
      this.saving = true;
      // set isLoading to true to show progressbar if response is not immidiate
      this.isLoading = true;
      apiDelete(`/vessel/${this.vessel.ID}`).then((result) => {
        this.saving = false;
        this.isLoading = false;
        window.messageBus.$emit('vesselDelete', this.vessel.ID);
        if (result.resultCategory == 1) {
          // this.$router.go(-1); //go back
          this.$router.push('/vessels');
        }
      });
    },

    getCode(roleId) {
      return GlobalHelperFunctions.lookupCode(roleId, this.helperStore.clientTypes, 'ID', 'NAME');
    },

    saveClicked() {
      this.$refs.vesselForm.validate();
      if (this.formValid) {
        this.saving = true;
        // set isLoading to true to show progressbar if response is not immidiate
        this.isLoading = true;
        const vesselNameChanged = this.vessel.NAME !== this.orgVessel.NAME;
        const vesselIMOChanged = this.vessel.LLOYDSNR !== this.orgVessel.LLOYDSNR;
        apiPut('/vessel', {
          value: this.vessel,
          orgValue: this.orgVessel,
          dynavalues: this.vessel.dynaValues,
          orgDynavalues: this.orgVessel.dynaValues,
        }).then((result) => {
          this.vessel = result.payload;
          const sednaApi = new SednaApiService(this.selectedAgency);
          if (this.vessel.ID && (vesselNameChanged || vesselIMOChanged) && sednaApi.isSednaIntegration) {
            sednaApi.updateSednaJobIdInSedna('vessel', this.vessel.ID, this.vessel.SEDNA_JOB_ID, []);
          }
          this.saving = false;
          this.isLoading = false;
          this.orgVessel = GatUtils.makeCopyViaJson(this.vessel);
          window.messageBus.$emit('vesselUpdate', this.vessel);
          this.$nextTick(() => {
            if (!this.value || (this.value && this.value.ID == 0)) {
              this.$router.replace(`/vessel/${result.payload.ID}`);
            }
          });
        });
      }
    },

    roleFormClosed(params) {
      this.showRoleForm = false;
      if (params.btn == 'save') {
        apiPut('vesselhotlist', { value: params.value, orgValue: this.currentRole }).then((result) => {
          if (result.resultCategory == 1) {
            this.vessel.clients = result.payload;
            this.orgVessel.clients = this.vessel.clients; // to avoid save btn "changes"
          }
        });
      } else if (params.btn == 'delete') {
        apiDelete(`vesselhotlist/${params.value.VHO_ID}`).then(() => {
          const idx = this.vessel.clients.findIndex((item) => item.VHO_ID == params.value.VHO_ID);
          this.currentRole = params.value;
          this.vessel.clients.splice(idx, 1);
          this.orgVessel.clients = this.vessel.clients; // to avoid save btn "changes"
        });
      }
      this.currentRole = {};
    },

    checkDuplicateVesselName() {
      if (this.vessel.NAME && this.vessel.NAME.length >= 1) {
        apiGet(`vessel/exists/name/${this.vessel.NAME}`).then((result) => {
          if (!this.value || this.value.ID == 0) {
            // if new vessel
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            result.length != 0 ? (this.isVesselNameInUse = true) : (this.isVesselNameInUse = false);
          } else {
            // if updating existing vessel
            for (let i = 0; i < result.length; i++) {
              if (this.value.ID == result[i].ID) {
                result.splice(i, 1);
              }
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            result.length >= 1 ? (this.isVesselNameInUse = true) : (this.isVesselNameInUse = false);
          }
          if (this.isVesselNameInUse) {
            this.duplicateVesselNames = result;
          }
        });
      } else {
        this.isVesselNameInUse = false;
      }
    },

    checkDuplicateVesselImo() {
      if (this.vessel.LLOYDSNR && this.vessel.LLOYDSNR.length == 7) {
        apiGet(`vessel/exists/imo/${this.vessel.LLOYDSNR}`).then((result) => {
          if (!this.value || this.value.ID == 0) {
            // if new vessel
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            result.length != 0 ? (this.isVesselImoInUse = true) : (this.isVesselImoInUse = false);
          } else {
            // if updating existing vessel
            for (let i = 0; i < result.length; i++) {
              if (this.value.ID == result[i].ID) {
                result.splice(i, 1);
              }
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            result.length >= 1 ? (this.isVesselImoInUse = true) : (this.isVesselImoInUse = false);
          }
          if (this.isVesselImoInUse) {
            this.duplicateVesselIMO = result;
          }
        });
      } else {
        this.isVesselImoInUse = false;
      }
    },

    vesselImport(v) {
      const importedVessel = {
        ...v,
        NATIONAL_CODES_ID: this.helperStore.getCountryValueFromCountryName(v.FLAG),
      };
      delete importedVessel.FLAG;
      this.vessel = importedVessel;
      this.importedFrom = importedVessel.LLOYDSNR;
      // Because the input fields event listener is @blur we need to check for duplicates on import
      this.checkDuplicateVesselName();
      this.checkDuplicateVesselImo();
    },

    leftWithoutSaving() {
      this.vessel = GatUtils.makeCopyViaJson(this.value);
      this.orgVessel = GatUtils.makeCopyViaJson(this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.vesselImg {
  margin-top: -20px;
}

.custom-warning {
  position: fixed;
  z-index: 5000;
  top: 80px;
  width: auto;
}

a {
  color: #2196f3 !important;
  text-decoration: none;
}
</style>
