const proformaRoutes = [
  {
    path: 'proforma',
    name: 'proformaList',
    props: true,
    // props: ({params}) => ({callId:+params.callId}),
    component: () => import('../components/ProformaLayout.vue'),
    children: [
      {
        name: 'proformaList',
        path: '',
        props: true,
        // props: ({params}) => ({callId:+params.callId}),
        component: () => import('../components/ProformaList.vue'),
      },
      {
        name: 'proformaItem',
        path: ':proformaId',
        props: true,

        component: () => import('../components/Proforma.vue'),
      },
    ],
  },
];

export default proformaRoutes;
