<template>
  <record-presenter :value="state" />
</template>

<script setup>
import { useAppStatusStore } from '@/store/appStatusStore';
import { useApplicationStore } from '@/store/applicationStore';
import { useAttendanceStore } from '@/store/attendanceStore';
import { usePortcallStore } from '@/store/portcallStore';
import { useConstantsStore } from '@/store/constantsStore';
import { useDashboardStore } from '@/store/dashboardStore';
import { useGatComponentsStore } from '@/store/gatComponentsStore';
import { useHelperStore } from '@/store/helperStore';
import { useToolbarStore } from '@/store/toolbarStore';
import { computed } from 'vue';

const applicationStore = useApplicationStore();
const appStatusStore = useAppStatusStore();
const portcallStore = usePortcallStore();
const attendanceStore = useAttendanceStore();
const constantsStore = useConstantsStore();
const dashboardStore = useDashboardStore();
const gatComponentsStore = useGatComponentsStore();
const helperStore = useHelperStore();
const toolbarStore = useToolbarStore();

const state = computed(() => ({
  applicationStore,
  appStatusStore,
  portcallStore,
  attendanceStore,
  constantsStore,
  dashboardStore,
  gatComponentsStore,
  helperStore,
  toolbarStore,
}));
</script>

<style scoped></style>
