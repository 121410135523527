import { render, staticRenderFns } from "./PortcallDetails.vue?vue&type=template&id=7ad66474&scoped=true&"
import script from "./PortcallDetails.vue?vue&type=script&lang=js&"
export * from "./PortcallDetails.vue?vue&type=script&lang=js&"
import style0 from "./PortcallDetails.vue?vue&type=style&index=0&id=7ad66474&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ad66474",
  null
  
)

export default component.exports