<template>
  <div>
    <gat-grid
      title="Visitors"
      class="elevation-0"
      :items="visitors"
      :columns="columns"
      :fixedheader="true"
      :height="showAll ? 'calc(100vh - 110px)' : 'calc(100vh - 135px)'"
      reloadBtn
      :orgSortBy="orgSortColumns"
      @reload-clicked="retrieveVisitors(callId)"
      @row-clicked="editVisitor"
      :loading="isLoading"
      :selectable="!showAll"
      :selectedKeys="selectedItemKeys"
      @selectionChanged="visitorSelected($event)"
      keyName="VIS_ID">
      <template slot="btns">
        <wm-button-group
          v-if="!showAll"
          class="right mr-1"
          :buttons="wmButtonGroup"
          @addClick="addVisitor"
          @addFromTemplateClick="showAddTemplateDialog = true"
          @createEmailClick="(val) => createEmailClick(val)" />
      </template>

      <template slot="cell" slot-scope="props">
        <div v-if="props.column.field == 'MASTER_EMAIL'">
          <div v-if="props.row.MASTER_EMAIL" @click.stop class="d-flex flex-column">
            <template v-for="email in formatCommaseperatedString(props.row.MASTER_EMAIL)">
              <a class="mr-2 gridLink" :href="`mailto:${props.row.MASTER_EMAIL}`" :key="email">{{ email }}</a>
            </template>
          </div>
        </div>
        <div v-else-if="props.column.field == 'PERSON_IN_CHARGE_EMAIL'" class="d-flex">
          <div v-if="props.row.PERSON_IN_CHARGE_EMAIL" @click.stop>
            <a class="mr-2 gridLink" :href="`mailto:${props.row.PERSON_IN_CHARGE_EMAIL}`">{{
              props.row.PERSON_IN_CHARGE_EMAIL
            }}</a>
          </div>
        </div>
        <div
          v-else-if="props.column.field === 'MASTER_MOBILE_NUMBERS' && props.row.MASTER_MOBILE_NUMBERS"
          class="d-flex flex-column">
          <template v-for="mobileNumber in formatCommaseperatedString(props.row.MASTER_MOBILE_NUMBERS)">
            <a :href="`tel:${mobileNumber}`" class="gridLink" :key="mobileNumber">{{ mobileNumber }}</a>
          </template>
        </div>
        <!--
                 <div v-else-if="props.column.field=='CONTACT_PERSON'" class="d-flex">
                    <div v-if="props.row.PERSON_IN_CHARGE_NAME" @click.stop>
                        <div>
                            {{props.row.PERSON_IN_CHARGE_NAME}}
                        </div>
                        <div v-if="props.row.PERSON_IN_CHARGE_MOBILE" >
                            <a  class="mr-2 gridLink" :href="`tel:${props.row.PERSON_IN_CHARGE_MOBILE}`">{{props.row.PERSON_IN_CHARGE_MOBILE}}</a>
                        </div>
                        <div>
                        <a class="mr-2 gridLink" :href="`mailto:${props.row.PERSON_IN_CHARGE_EMAIL}`">{{props.row.PERSON_IN_CHARGE_EMAIL}}</a>
                        </div>
                    </div>
                </div> -->
      </template>
    </gat-grid>
    <VisitorTemplateDialog
      v-if="showAddTemplateDialog"
      :showDialog="showAddTemplateDialog"
      :callId="+callId"
      @close="showAddTemplateDialog = false" />
    <email-generator :portcallId="callId" location="visitor" :guid="emailTemplateGuid" />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { apiPut, apiGet } from '@/store/api';
import { useHelperStore } from '@/store/helperStore';
import { usePortcallStore } from '@/store/portcallStore';
import { useAppStatusStore } from '@/store/appStatusStore';
import { useApplicationStore } from '@/store/applicationStore';
import EmailGenerator from '@/app-components/EmailGenerator.vue';
import { getVisitors } from '@/services/api/api-calls/getVisitors';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import VisitorTemplateDialog from './VisitorTemplateDialog.vue';

export default {
  name: 'VisitorList',
  props: {
    callId: [Number, String],
    /** Shows all visitors for this agency */
    showAll: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VisitorTemplateDialog,
    EmailGenerator,
  },
  setup() {
    const appStatusStore = useAppStatusStore();
    const applicationStore = useApplicationStore();
    const portcallStore = usePortcallStore();
    const helperStore = useHelperStore();
    return {
      portcallStore,
      applicationStore,
      appStatusStore,
      helperStore
    };
  },
  data() {
    return {
      orgSortColumns: [{ header: 'Date' }, { header: 'Time' }],
      isLoading: false,
      showAddTemplateDialog: false,
      emailTemplates: [],
      emailTemplateGuid: undefined,
      selectedItemKeys: [],
    };
  },

  activated() {
    this.$nextTick(() => {
      if (this.showAll) {
        if (this.portcallStore.visitorsExtended && this.portcallStore.visitorsExtended.length === 0) {
          this.retrieveVisitors(this.callId);
        }
      } else if (this.portcallStore.visitors && this.portcallStore.visitors.length === 0) {
        this.retrieveVisitors(this.callId);
      }
    });
  },

  computed: {
    agencyId() {
      return this.portcallStore.callDetails.SETUP_ID;
    },
    wmButtonGroup() {
      const result = [];
      result.push({
        btnType: 'ADD',
        openMenuOnHover: true,
        embeddMenuItems: true,
        mainAction: true,
        disabled: !this.canAddVisitors,
        menuItems: [
          { caption: 'Add Visitor', eventName: 'addClick', icon: 'mdi-plus' },
          { caption: 'Add from template', eventName: 'addFromTemplateClick', icon: 'mdi-content-duplicate' },
        ],
      });
      if ((!this.applicationStore.user.internal && this.userCanGenerateDoc) || this.applicationStore.user.internal) {
        result.push({
          btnType: 'CREATE_DOC',
          docType: 8,
          agencyId: this.agencyId,
          location: 'portcall',
          foreignKey: this.callId,
          applicationStatus: this.applicationStatus,
          disabled: !this.userCanGenerateDoc,
        });
        if (
          this.applicationStore.globalSettings.SGL_WM_EMAIL_PROVIDER !== 0 &&
          this.applicationStore.userRight('EM.EDIT')
        ) {
          result.push({
            btnType: 'CUSTOM',
            caption: 'Create email',
            icon: 'mdi-email',
            menuItems: this.emailTemplateItems,
            disabled: this.emailTemplateItems.length === 0,
          });
        }
      }
      return result;
    },
    userCanGenerateDoc() {
      const isInternal = this.applicationStore.user.internal;
      if (isInternal) {
        return true;
      }
      const externalRights = this.applicationStore.userRight('VI.GENERATE_DOCUMENT');
      return externalRights;
    },
    canAddVisitors() {
      if (this.applicationStore.user.internal) {
        return this.applicationStore.userRight('P.NEW_AND_EDIT');
      }
      return this.applicationStore.userRight('VI.NEW_AND_EDIT');
    },

    columns() {
      const result = [];
      const settings = this.applicationStore.getAgencySettings(this.portcallStore.callDetails.SETUP_ID);
      if (this.showAll) {
        result.push({ header: 'Date', field: 'VIS_DATE', dataType: 'date', width: '1%', nowrap: true });
        result.push({ header: 'Time', field: 'VIS_TIME', dataType: 'time', width: '1%', nowrap: true });
        result.push({ header: 'Name', field: 'VIS_NAME', nowrap: true });
        result.push({
          header: 'Phone',
          field: 'VIS_PHONE',
          nowrap: true,
          linkUrl: 'tel:field[VIS_PHONE]',
          linkText: 'field[VIS_PHONE]',
        });
        result.push({ header: 'Company', field: 'VIS_COMPANY', nowrap: true });
        result.push({ header: 'Vessel', field: 'VESSEL_NAME', nowrap: true });
        result.push({ header: 'Quay', field: 'QUAY', nowrap: true });
        // result.push({"header":"Vessel contact","field":"CONTACT_MASTER", nowrap:true, hide:true});
        // result.push({"header":"Agent contact","field":"CONTACT_PERSON", nowrap:true, hide:true});
        result.push({ header: 'Vessel contact', field: 'MASTER_NAME', nowrap: true });
        result.push({ header: 'Vessel contact phone', field: 'MASTER_MOBILE_NUMBERS', nowrap: true });
        result.push({ header: 'Vessel contact e-mail', field: 'MASTER_EMAIL', nowrap: true });
        result.push({ header: 'Agent contact', field: 'PERSON_IN_CHARGE_NAME', nowrap: true });
        result.push({
          header: 'Agent phone',
          field: 'PERSON_IN_CHARGE_MOBILE',
          nowrap: true,
          linkUrl: 'tel:field[PERSON_IN_CHARGE_MOBILE]',
          linkText: 'field[PERSON_IN_CHARGE_MOBILE]',
        });
        result.push({ header: 'Agent e-mail', field: 'PERSON_IN_CHARGE_EMAIL', nowrap: true });
      } else {
        result.push({ header: 'Date', field: 'VIS_DATE', dataType: 'date' });
        result.push({ header: 'Time', field: 'VIS_TIME', dataType: 'time' });
        result.push({ header: 'Company', field: 'VIS_COMPANY' });
        result.push({ header: 'Name', field: 'VIS_NAME' });
        result.push({ header: 'Phone', field: 'VIS_PHONE' });
        result.push({ header: 'Other', field: 'VIS_OTHER' });
        for (let i = 1; i <= 6; i++) {
          if (settings[`VIS_USER${i}`]) {
            result.push({ header: settings[`VIS_USER${i}`], field: `VIS_USER${i}` });
          }
        }
      }

      return result;
    },
    visitors() {
      return this.showAll ? this.portcallStore.visitorsExtended : this.portcallStore.visitors;
    },
    applicationStatus() {
      return this.appStatusStore.getAppStatus;
      /* let result = {};
                result.PortCall = {};
                result.PortCall.Id = this.callId;
                if(Array.isArray(this.selectedItemKeys)){
                    result.PortCall.VisitorIds = this.selectedItemKeys
                }
                return result; */
    },
    emailTemplateItems() {
      let result = [];
      const ignoreGroupMinCount = this.applicationStore.hasFeatureCode('IGNORE_DOCUMENT_GROUPING_MIN_COUNT');

      const templates = [...this.emailTemplates];
      if (Array.isArray(templates) && templates.length > 0) {
        if (ignoreGroupMinCount ? true : templates.length > 10) {
          result = this.helperStore.emailTemplatesGroupedByLabels(templates, false);
        } else {
          result = templates.map((item) => ({
            caption: item.TITLE,
            eventName: 'createEmailClick',
            icon: 'mdi-file-outline',
            ID: item.ID,
          }));
        }
      }
      return result;
    },
  },

  methods: {
    visitorSelected(visitorIds) {
      this.selectedItemKeys = visitorIds;
      this.appStatusStore.setAppStatusVisitorSelectedIds(visitorIds);
    },
    createEmailClick(item) {
      if (this.callId) {
        const guid = GlobalHelperFunctions.GUID();
        const templateId = item.ID;
        const location = 'portcall';
        const foreignKey = this.callId;
        const appStatus = JSON.stringify(this.applicationStatus);
        const param = { guid, templateId, location, foreignKey, appStatus, isEmail: 1 };
        apiPut('document/generate/', param).then((result) => {
          if (result.resultCategory === 0 && result.resultType === 0) {
            this.emailTemplateGuid = guid;
          }
        });
      }
    },
    formatCommaseperatedString(str) {
      const result = [];
      if (str.includes(',')) {
        const splitted = str.split(',').map((item) => item.trim());
        splitted.forEach((element) => {
          result.push(element);
        });
      } else if (str) {
        result.push(str);
      }
      return result;
    },
    retrieveVisitors(callId, tries = 0) {
      if (Object.keys(this.applicationStore.user.userRights).length === 0 && tries <= 5) {
        // Call this method again if the userRights are not loaded.
        setTimeout(() => {
          // eslint-disable-next-line no-param-reassign
          this.retrieveVisitors(callId, tries++);
        }, 1000);
        return;
      }
      if (
        this.applicationStore.user.internal ||
        this.applicationStore.userRight('VI.GATEKEEPER') ||
        this.applicationStore.userRight('VI.ACCESS')
      ) {
        this.isLoading = true;
        const { showAll } = this;
        getVisitors({ callId, showAll })
          .then(() => {
            apiGet(`mail/templates/visitor/${this.agencyId}/${true}`).then((result) => {
              if (result) {
                this.emailTemplates = result;
              }
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    addVisitor() {
      this.$router.push(`/call/${this.callId}/new-visitor-item/`);
    },
    editVisitor(row) {
      const callId = this.callId || row.VIS_PORTCALL_ID;
      if (
        callId &&
        (this.applicationStore.userRight('VI.ACCESS') || this.applicationStore.userRight('VI.NEW_AND_EDIT'))
      ) {
        this.$router.push(`/call/${callId}/visitor-item/${row.VIS_ID}`);
      }
    },
  },
};
</script>

<style scoped></style>
