
import Vue from 'vue';
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPost, apiPut, apiDelete } from '@/store/api';
import EmailGenerator from '@/app-components/EmailGenerator.vue';
import { v4 as uuidv4 } from 'uuid';
import GatUtils from '@/app-components/GatUtils';
import { usePortcallStore } from '@/store/portcallStore';
import { useAppStatusStore } from '@/store/appStatusStore';
import { useApplicationStore } from '@/store/applicationStore';
import { useHelperStore } from '@/store/helperStore';
import { getCargoItems } from '@/services/api/api-calls/getCargoItems';
import { getHotlist } from '@/services/api/api-calls/getHotlist';
import ProspectItem from './ProspectItem.vue';

export default Vue.extend({
  name: 'ProspectsList',
  props: {
    callId: [String, Number],
  },
  components: { EmailGenerator, GatUtils, ProspectItem },
  setup() {
    const portcallStore = usePortcallStore();
    const appStatusStore = useAppStatusStore();
    const applicationStore = useApplicationStore();
    const helperStore = useHelperStore();
    return {
      applicationStore,
      portcallStore,
      appStatusStore,
      helperStore,
    };
  },
  data() {
    return {
      isSaving: false,
      selectedIds: [],
      emailTemplates: [],
      selectedProspect: null,
      prospectItems: [] as any[],
      prospectDialogVisible: false,
      deleteProspecstDialog: false,
      emailTemplateGuid: undefined,
      showEmailStatusDialog: false,
      clientsContacts: [] as { ID: any; ROLE: any; NAME: any; EMAIL: any; TYPE: number; CLIENT_ROLE_ID: any; data: any; }[],
    };
  },

  computed: {
    callDetails() {
      return this.portcallStore.callDetails;
    },
    hotlist() {
      return this.portcallStore.hotlist;
    },
    canCreateNewProspect() {
      return this.applicationStore.userRight('PRO.NEW') && this.applicationStore.user.internal;
    },
    canEditProspect() {
      return this.applicationStore.userRight('PRO.EDIT') && this.applicationStore.user.internal;
    },
    canDeleteProspects() {
      return this.applicationStore.userRight('PRO.DELETE') && this.applicationStore.user.internal;
    },
    columns() {
      const result = [];
      result.push({ header: 'Name', field: 'CHO_NAME', hideOnPhone: false });
      result.push({ header: 'Subject', field: 'CHO_SUBJECT', hideOnPhone: false });
      result.push({ header: 'Updated', field: 'Updated', hideOnPhone: false });
      result.push({ header: 'Updated by', field: 'CHO_LAST_UPDATED_BY', hideOnPhone: false });
      result.push({ header: 'Client', field: 'Client', hideOnPhone: true });
      result.push({ header: 'Cargo', field: 'Cargo', hideOnPhone: true });
      result.push({ header: 'SOF', field: 'Sof', hideOnPhone: true });
      return result;
    },

    emailTemplateItems() {
      const templates = [...this.emailTemplates];
      let result: any[] = [];
      const ignoreGroupMinCount = this.applicationStore.hasFeatureCode('IGNORE_DOCUMENT_GROUPING_MIN_COUNT');

      if (Array.isArray(templates) && templates.length > 0) {
        if (ignoreGroupMinCount ? true : templates.length > 10) {
          result = this.helperStore.emailTemplatesGroupedByLabels(templates, false);
        } else {
          result = templates.map((item: any) => ({
            caption: item.TITLE,
            eventName: 'createEmailClick',
            icon: 'mdi-file-outline',
            ID: item.ID,
          }));
        }
      }

      return result;
    },

    applicationStatus() {
      return this.appStatusStore.getAppStatus;
    },
  },

  activated() {
    this.isSaving = false;
    this.prospectDialogVisible = false;
    if (this.callId) this.getProspectLines(this.callId);
  },

  created() { },

  watch: {
    callId: {
      handler(val) {
        this.getProspectLines(val);
        getCargoItems(val);
        getHotlist(val);
      },
      immediate: true,
    },
    hotlist: {
      async handler(clients) {
        if (clients) {
          const promises: any[] = [];
          const clientContacts: any[] = [];
          clients.forEach((client: { H_ID: any; CLIENT_ID: any; }) => {
            promises.push(
              apiPost('contacts/', {
                maxRows: 200,
                searchStr: '',
                id: -1,
                category: -1,
                clientId: client.CLIENT_ID,
                includeInactive: false,
              }).then((contacts) => {
                clientContacts.push({ ...client, CONTACT_LIST: contacts.filter((c: any) => !c.INACTIVE) });
              })
            );
          });

          await Promise.allSettled(promises);

          const dataset: { ID: any; ROLE: any; NAME: any; EMAIL: any; TYPE: number; CLIENT_ROLE_ID: any; data: any; }[] = [];
          clientContacts.forEach(d => {
            dataset.push({ ID: d.CLIENT_ID, ROLE: d.CT_NAME, NAME: d.C_NAME, EMAIL: d.C_EMAIL, TYPE: d.H_ID == -1 ? 3 : 1, CLIENT_ROLE_ID: d.CLIENT_ROLE_ID, data: d })
            d.CONTACT_LIST.forEach(((c: { ID: any; C_NAME: any; LAST_NAME: any; FIRST_NAME: any; EMAIL: any; }) => {
              dataset.push({ ID: c.ID, ROLE: d.CT_NAME, NAME: `${c.C_NAME} - ${c.LAST_NAME}, ${c.FIRST_NAME}`, EMAIL: c.EMAIL, TYPE: 2, CLIENT_ROLE_ID: d.CLIENT_ROLE_ID, data: c })
            }))
          })

          dataset.sort((a, b) => a.NAME.localeCompare(b.NAME))
          this.clientsContacts = dataset;
        }
      },
      immediate: true,
    }
  },

  methods: {
    wmButtonGroup() {
      const result = [];
      result.push({
        btnType: 'ADD',
        docType: 19,
        eventName: 'addNewProspectClicked',
        agencyId: this.callDetails.SETUP_ID,
        applicationStatus: this.applicationStatus,
        location: 'portcall',
        foreignKey: this.callId,
        disabled: !this.canCreateNewProspect,
      });
      result.push({
        btnType: 'CUSTOM',
        icon: 'mdi-circle-edit-outline',
        caption: 'EDIT',
        eventName: 'editSelectedProspectClicked',
        docType: 19,
        agencyId: this.callDetails.SETUP_ID,
        applicationStatus: this.applicationStatus,
        location: 'portcall',
        foreignKey: this.callId,
        disabled: this.selectedIds.length !== 1 || !this.canEditProspect,
      });
      result.push({
        btnType: 'DELETE',
        eventName: 'deleteSelectedProspectsClicked',
        docType: 19,
        agencyId: this.callDetails.SETUP_ID,
        applicationStatus: this.applicationStatus,
        location: 'portcall',
        foreignKey: this.callId,
        disabled: this.selectedIds.length < 1 || !this.canDeleteProspects,
      });
      result.push({
        btnType: 'CREATE_DOC',
        docType: 19,
        agencyId: this.callDetails.SETUP_ID,
        applicationStatus: this.applicationStatus,
        location: 'portcall',
        foreignKey: this.callId,
        disabled: this.selectedIds.length !== 1,
      });
      result.push({
        btnType: 'CUSTOM',
        caption: 'Create email',
        icon: 'mdi-email',
        menuItems: this.emailTemplateItems,
        disabled: this.selectedIds.length !== 1 || this.emailTemplateItems.length === 0,
      });
      return result;
    },
    hasAgencySetting(value: string) {
      value.trim();
      let result = 0;
      const portcallAgency = this.callDetails.SETUP_ID; // Agency to which the current portcall belongs.
      if (portcallAgency) {
        const idx = this.applicationStore.agencySettings.findIndex((item: any) => item.ID == portcallAgency); // Find agencySettings for portcallAgency
        if (idx >= 0) {
          const agency = this.applicationStore.agencySettings[idx]; // Store all the agency settings.
          result = agency[value]; // Check the agency setting for the given argument.
        }
      }
      return result == 1;
    },
    getProspectLines(portCallId: string | number) {
      this.prospectItems = [];
      this.emailTemplates = [];

      apiGet(`prospects/${portCallId}`).then((response: any) => {
        this.prospectItems = [...response];
        this.portcallStore.setTabHasData({ tabName: 'PROSPECTS', value: response.length });
      });

      apiGet(`mail/templates/prospects/${this.callDetails.SETUP_ID}/${true}`).then((result: any) => {
        if (result) {
          this.emailTemplates = result;
        }
      });

      const param = GatUtils.makeCopyViaJson(this.portcallStore.sendEmailProspects);
      this.portcallStore.setSendEmailProspects(undefined);
      this.sendEmail(param);
    },
    onRowClicked(item: any) {
      if (item && item.CHO_ID) this.$router.push({ name: 'prospectDetails', params: { prospectId: item.CHO_ID } });
    },
    prospectsGridSelectionChanged(item: any) {
      this.appStatusStore.setAppStatusSelectedProspectId(item[0]);
      this.selectedIds = item;
    },
    buttonGroupClicked(btn: any) {
      const event = btn.button.eventName;
      if (event === 'addNewProspectClicked') {
        this.selectedProspect = null
        this.prospectDialogVisible = true
      } else if (event === 'editSelectedProspectClicked') {
        if (this.selectedIds.length === 1) {
          this.selectedProspect = this.prospectItems.find(item => item.CHO_ID == this.selectedIds[0])
          this.prospectDialogVisible = true
        }
      } else if (event === 'deleteSelectedProspectsClicked') {
        this.deleteProspecstDialog = true
      }
    },
    closeProspectDialog(cancelled: boolean) {
      this.prospectDialogVisible = false;
      if (this.callId && !cancelled) {
        this.getProspectLines(this.callId)
      }
    },
    async deleteSelectedProspects() {
      try {
        this.isSaving = true;
        const promises: any[] = [];
        this.selectedIds.forEach((selectedId) => {
          promises.push(
            apiDelete(`/prospects/${selectedId}`)
          );
        });
        await Promise.allSettled(promises);
        this.selectedIds = []
        this.getProspectLines(this.callId);
        this.isSaving = false;
        this.deleteProspecstDialog = false;
      } catch {
        this.isSaving = false;
      } finally {
        this.isSaving = false;
      }
    },
    createEmailClick(item: any) {
      if (this.callId) {
        const guid = uuidv4();
        const templateId = item.ID;
        const location = 'portcall';
        const foreignKey = this.callId;
        const appStatus = JSON.stringify(this.applicationStatus);
        const param = { guid, templateId, location, foreignKey, appStatus, isEmail: 1 };
        this.sendEmail(param);
      }
    },
    sendEmail(param: any) {
      if (param && param.guid && param.location && param.foreignKey && param.appStatus && param.templateId) {
        const { guid } = param;
        apiPut('document/generate/', param).then((result) => {
          if (result.resultCategory === 0 && result.resultType === 0) {
            this.emailTemplateGuid = guid;
            this.showEmailStatusDialog = true;
          }
        });
      }
    },

    formatProspectSemicolonString(item: any) {
      let values = null;
      let value = item;
      if (item) {
        values = item.split(';');
        if (typeof values == 'object' && values.length >= 2) {
          // eslint-disable-next-line prefer-destructuring
          value = values[0];
        }
        values.splice(0, 1);
      }
      return { value, values };
    },
  },
});
