<template>
  <div class="pb-5">
    <gat-form :readonly="!allowSave" v-model="isValid" ref="form" :isLoading="isLoading">
      <GatGroup
        class="pa-0"
        title="Customer / supplier details"
        :readonly="!applicationStore.userRight('C.EDIT')"
        :collapsed="isNew ? !expandedIfNew : !(value && value.ID == 0)">
        <template slot="compact">
          <v-layout wrap class="d-flex flex-row">
            <gat-compact-field :value="client.NAME" label="Company name" />
            <gat-compact-field
              :value="categoryName(client.CATEGORY)"
              label="Category"
              v-if="applicationStore.user.internal" />
            <gat-compact-field :value="client.PHONE" label="Phone no">
              <a class="gridLink" :href="`tel:${client.PHONE}`" @click.stop>{{ client.PHONE }}</a>
            </gat-compact-field>
            <gat-compact-field :value="client.EMAIL" label="Email">
              <a class="gridLink" :href="`mailto:${client.EMAIL}`" @click.stop>{{ client.EMAIL }}</a>
            </gat-compact-field>
            <gat-compact-field :value="client.ACCOUNT_NO" label="Client no" size="xs" />
            <gat-compact-field
              v-if="client.INACTIVE"
              :value="client.INACTIVE"
              label="Inactive"
              :checkbox="true"
              size="xs" />
          </v-layout>
          <gat-dyna-fields
            v-if="applicationStore.user.internal"
            v-model="client.dynaValues"
            componentName="tsOurData.ClientDyna"
            container="Upper part of window"
            :compact="true"></gat-dyna-fields>
        </template>

        <v-layout wrap>
          <gat-edit
            v-model="client.NAME"
            :autofocus="value && value.ID == 0"
            label="Name"
            size="xs12 s12 md4"
            :required="true"
            :maxChars="applicationStore.getColumnLength('Client.NAME')" />
          <gat-edit
            v-model="client.ALPHANAME"
            label="Alt name"
            v-if="applicationStore.user.internal"
            size="xs12 md4"
            :maxChars="applicationStore.getColumnLength('Client.ALPHANAME')" />
          <gat-head-office-select
            v-if="applicationStore.user.internal"
            size="xs12 s12 md4"
            :category="client.CATEGORY"
            :agencyId="agencyId"
            v-model="client.HEAD_OFFICE_ID"
            :clientId="client.ID"
            customItem />
          <gat-edit
            v-model="client.PHONE"
            label="Phone"
            size="xs12 md4"
            :readonly="!(applicationStore.userRight('C.EDIT') || !applicationStore.user.internal)"
            :maxChars="applicationStore.getColumnLength('Client.PHONE')"
            tel />
          <div class="d-flex ma-0 pa-0" :class="$vuetify.breakpoint.mdAndDown ? ' col-12 row-1' : ' col-4 row-1'">
            <gat-edit
              v-model="client.ACCOUNT_NO"
              label="Client no"
              v-if="applicationStore.user.internal"
              :disabled="applicationStore.globalSettings.SGL_ENABLE_CLIENT_CODE === 0"
              size="xs4"
              :required="isRequired('SGL_CLIENT_MAN_NUMBER')"
              :maxChars="applicationStore.getColumnLength('Client.ACCOUNT_NO')" />
            <gat-select
              v-model="client.CATEGORY"
              label="Category"
              v-if="applicationStore.user.internal"
              :required="applicationStore.globalSettings.SGL_CLIENT_MAN_CATEGORY === 1"
              size="xs8"
              :items="clientCategories"
              :clearable="applicationStore.globalSettings.SGL_CLIENT_MAN_CATEGORY !== 1">
            </gat-select>
          </div>
          <gat-edit
            v-model="client.OFFICE_DESCRIPTION"
            label="Office description"
            size="xs12 md4"
            :maxChars="applicationStore.getColumnLength('Client.OFFICE_DESCRIPTION')" />
          <gat-edit
            v-model="client.EMAIL"
            label="Email"
            size="xs12 md4"
            :readonly="!(applicationStore.userRight('C.EDIT') || !applicationStore.user.internal)"
            :required="isRequired('SGL_CLIENT_MAN_EMAIL')"
            :maxChars="applicationStore.getColumnLength('Client.EMAIL')"
            mailto />
        </v-layout>
        <gat-dyna-fields
          v-if="applicationStore.user.internal"
          v-model="client.dynaValues"
          componentName="tsOurData.ClientDyna"
          container="Upper part of window"
          :compact="false"></gat-dyna-fields>
        <v-layout wrap>
          <gat-edit
            v-model="client.INACTIVE"
            label="Inactive"
            size="s"
            checkbox
            :checkedValue="1"
            :uncheckedValue="0"
            :readonly="!applicationStore.user.internal">
          </gat-edit>
          <gat-edit
            v-show="applicationStore.globalSettings.SGL_USE_WEBMODULE_2021 === webModuleInternalAndExternal"
            v-model="client.WEBMODULEACCESS"
            label="External web module access"
            size="sm"
            checkbox
            :checkedValue="1"
            :uncheckedValue="0"
            :readonly="!applicationStore.user.internal">
          </gat-edit>
        </v-layout>
      </GatGroup>

      <GatGroup
        v-if="applicationStore.user.internal"
        class="pa-0"
        title="Remarks"
        :readonly="!applicationStore.userRight('C.EDIT')"
        :collapsed="isNew ? !expandedIfNew : value.ID ? true : false">
        <template slot="compact">
          <v-layout class="pr-3">
            <gat-compact-field v-model="client.NEW_COMMENTS" label="Comments" size="xs4" />
            <gat-compact-field v-model="client.NEW_INSTRUCTIONS" label="Instructions" size="xs4" />
          </v-layout>
        </template>
        <v-layout wrap>
          <GatSubGroup title="" size="xs12">
            <gat-edit v-model="client.NEW_COMMENTS" label="Comments" size="xs12 lg6" :rows="5" :maxChars="0">
            </gat-edit>
            <gat-edit v-model="client.NEW_INSTRUCTIONS" label="Instructions" size="xs12 lg6" :rows="5" :maxChars="0">
            </gat-edit>
          </GatSubGroup>
        </v-layout>
      </GatGroup>
      <GatGroup
        v-if="applicationStore.user.internal"
        class="pa-0"
        title="Alert"
        :readonly="!applicationStore.userRight('C.EDIT')"
        :collapsed="isNew ? !expandedIfNew : value.ID ? true : false">
        <template slot="compact">
          <v-layout class="pr-3">
            <gat-compact-field
              v-model="client.WARNING"
              size="xs4"
              prepend-icon="notification_important"
              icon-color="orange" />
          </v-layout>
        </template>
        <v-layout wrap>
          <gat-edit
            v-model="client.SHOW_WARNING"
            label="Show alert when this client is used"
            size="xs12"
            checkbox
            :checkedValue="1"
            :uncheckedValue="0">
          </gat-edit>
          <gat-edit v-model="client.WARNING" label="Alert message" size="xs12" :rows="5" :maxChars="0"> </gat-edit>
        </v-layout>
      </GatGroup>

      <GatGroup
        class="pa-0"
        title="Addresses"
        :readonly="!(applicationStore.userRight('C.EDIT') || !applicationStore.user.internal)"
        :collapsed="isNew ? !expandedIfNew : !(value && value.ID == 0)">
        <template slot="compact">
          <v-layout wrap>
            <gat-compact-field
              :value="client.ADDRESS + ', ' + client.ZIP + ' ' + client.CITY + ', ' + client.NATIONAL_CODES_ID"
              label="Post"
              size="xs6" />
            <gat-compact-field
              v-if="client.ADR_MULTI"
              :value="
                client.ADR_INVOICE_STREET +
                ', ' +
                client.ADR_INVOICE_ZIP +
                ' ' +
                client.ADR_INVOICE_CITY +
                ', ' +
                client.ADR_INVOICE_COUNTRY_CODE
              "
              label="Invoice"
              size="xs6" />
          </v-layout>
        </template>
        <v-layout wrap>
          <GatSubGroup title="Post address">
            <gat-edit
              v-model="client.ADDRESS"
              label="Street address"
              size="xs12"
              :rows="2"
              :required="isRequired('SGL_CLIENT_MAN_POST_ADDRESS')"
              :maxChars="0">
            </gat-edit>
            <gat-edit
              v-model="client.ZIP"
              label="Zip"
              size="xs4"
              :required="isRequired('SGL_CLIENT_MAN_POST_ZIP')"
              :maxChars="applicationStore.getColumnLength('Client.ZIP')">
            </gat-edit>
            <gat-edit
              v-model="client.CITY"
              label="City"
              size="xs8"
              :required="isRequired('SGL_CLIENT_MAN_POST_CITY')"
              :maxChars="applicationStore.getColumnLength('Client.CITY')">
            </gat-edit>
            <gat-select
              v-model="client.NATIONAL_CODES_ID"
              label="Country"
              size="xs12"
              :items="countries"
              :required="isRequired('SGL_CLIENT_MAN_POST_COUNTRY')">
            </gat-select>
          </GatSubGroup>
          <GatSubGroup title="Visit address">
            <gat-edit v-model="client.ADR_VISIT_STREET" label="Street address" size="xs12" :rows="2" :maxChars="0">
            </gat-edit>
            <gat-edit
              v-model="client.ADR_VISIT_ZIP"
              label="Zip"
              size="xs4"
              :maxChars="applicationStore.getColumnLength('Client.ADR_VISIT_ZIP')">
            </gat-edit>
            <gat-edit
              v-model="client.ADR_VISIT_CITY"
              label="City"
              size="xs8"
              :maxChars="applicationStore.getColumnLength('Client.ADR_VISIT_CITY')">
            </gat-edit>
            <gat-select v-model="client.ADR_VISIT_COUNTRY_CODE" label="Country" size="xs12" :items="countries">
            </gat-select>
          </GatSubGroup>
          <GatSubGroup title="Invoice address">
            <gat-edit v-model="client.ADR_INVOICE_STREET" label="Street address" size="xs12" :rows="2" :max-chars="0">
            </gat-edit>
            <gat-edit
              v-model="client.ADR_INVOICE_ZIP"
              label="Zip"
              size="xs4"
              :maxChars="applicationStore.getColumnLength('Client.ADR_INVOICE_ZIP')">
            </gat-edit>
            <gat-edit
              v-model="client.ADR_INVOICE_CITY"
              label="City"
              size="xs8"
              :maxChars="applicationStore.getColumnLength('Client.ADR_INVOICE_CITY')">
            </gat-edit>
            <gat-select v-model="client.ADR_INVOICE_COUNTRY_CODE" label="Country" size="xs12" :items="countries">
            </gat-select>
          </GatSubGroup>
        </v-layout>
      </GatGroup>

      <GatGroup
        class="pa-0"
        title="Financial"
        :readonly="!applicationStore.userRight('C.EDIT')"
        :collapsed="isNew ? !expandedIfNew : !(value && value.ID == 0)">
        <template slot="compact">
          <v-layout wrap>
            <gat-compact-field :value="client.CURRENCY_NAME" label="Currency" size="xs" />
            <gat-compact-field
              v-if="client.CREDIT_TERM"
              :value="client.CREDIT_TERM + ' days'"
              label="Invoice terms"
              size="xs" />
            <gat-compact-field :value="client.ADVANCE" label="Ask for advance" checkbox size="xs" />
            <gat-compact-field
              v-if="client.HANDLING_PERCENT"
              :value="client.HANDLING_PERCENT + ' %'"
              label="Handling"
              size="xs" />
            <gat-compact-field :value="client.CREDIT_LIMIT" label="Credit limit" size="xs" />
            <gat-compact-field :value="client.FIXED_FEE" label="Agency fee" size="xs" />
            <gat-compact-field
              v-if="client.REBATE_PERCENT"
              :value="client.REBATE_PERCENT + ' %'"
              label="AF Rebate"
              size="xs" />
            <gat-compact-field :value="client.REBATE_FIXED" label="AF fixed rebate" size="xs" />
          </v-layout>
        </template>

        <v-layout wrap>
          <GatSubGroup title="Bank details">
            <gat-edit
              v-model="client.BANK_DETAILS"
              label="Bank name and address"
              size="xs12"
              :rows="2"
              :maxChars="applicationStore.getColumnLength('Client.BANK_DETAILS')"
              :readonly="!applicationStore.user.internal" />
            <gat-edit
              v-model="client.BANK_SWIFT"
              label="BIC / Swift"
              size="xs12"
              :maxChars="applicationStore.getColumnLength('Client.BANK_SWIFT')"
              :readonly="!applicationStore.user.internal" />
            <gat-edit
              v-model="client.BANK_ACCOUNT"
              label="Account no"
              size="xs12"
              :maxChars="applicationStore.getColumnLength('Client.BANK_ACCOUNT')"
              :readonly="!applicationStore.user.internal" />
            <gat-edit
              v-model="client.BANK_IBAN"
              label="IBAN"
              size="xs12"
              :maxChars="applicationStore.getColumnLength('Client.BANK_IBAN')"
              :readonly="!applicationStore.user.internal" />
          </GatSubGroup>

          <GatSubGroup title="Organisation">
            <gat-edit
              v-model="client.VAT_NO"
              label="VAT no"
              size="xs12"
              :readonly="
                !(
                  this.applicationStore.userRight('C.EDIT_VAT_NUMBER') ||
                  this.applicationStore.userRight('OC.EDIT_VAT_NUMBER')
                )
              "
              :required="isRequired('SGL_CLIENT_MAN_VAT', 'SGL_VAT_MANDATORY')"
              :maxChars="applicationStore.getColumnLength('Client.VAT_NO')" />
            <gat-edit
              v-model="client.EORI_NO"
              label="EORI no"
              size="xs12"
              :maxChars="applicationStore.getColumnLength('Client.EORI_NO')"
              :readonly="!applicationStore.user.internal" />
            <gat-select
              v-model="client.CURRENCY_NAME"
              label="Currency"
              size="xs6"
              :items="currencies"
              :readonly="!applicationStore.user.internal" />
          </GatSubGroup>

          <GatSubGroup title="Expense settings" v-if="applicationStore.user.internal">
            <gat-edit v-model="client.HANDLING_PERCENT" label="Handling" suffix="%" size="xs6" dataType="number">
            </gat-edit>
            <!--  -->
            <gat-edit v-model="client.TENDER_LIMIT" label="Tender limit" size="xs6" dataType="number"> </gat-edit>
            <gat-edit v-model="client.CREDIT_LIMIT" label="Credit limit" size="xs6" dataType="number"> </gat-edit>
            <gat-edit v-model="client.CREDIT_TERM" label="Invoice term" size="xs6" suffix="days" dataType="number">
            </gat-edit>

            <gat-edit
              v-model="client.ADVANCE"
              label="Ask for advance"
              size="xs12"
              checkbox
              :checkedValue="1"
              :uncheckedValue="0">
            </gat-edit>
          </GatSubGroup>

          <GatSubGroup title="Agency fee settings" v-if="applicationStore.user.internal">
            <gat-edit v-model="client.FIXED_FEE" dataType="number" label="Fixed fee" size="xs6"> </gat-edit>
            <gat-edit v-model="client.REBATE_PERCENT" dataType="number" label="Rebate" suffix="%" size="xs6">
            </gat-edit>
            <gat-edit v-model="client.REBATE_FIXED" dataType="number" label="Reabte (fixed)" size="xs6"> </gat-edit>
          </GatSubGroup>
        </v-layout>
      </GatGroup>

      <GatGroup
        v-if="applicationStore.user.internal"
        title="Our data"
        class="pa-0"
        :readonly="!applicationStore.userRight('C.EDIT')"
        :collapsed="isNew ? !expandedIfNew : !(value && value.ID == 0)">
        <template slot="compact">
          <gat-dyna-fields
            v-model="client.dynaValues"
            componentName="tsOurData.ClientDyna"
            container="Tab page"
            :compact="true"></gat-dyna-fields>
        </template>
        <gat-dyna-fields
          v-model="client.dynaValues"
          componentName="tsOurData.ClientDyna"
          container="Tab page"></gat-dyna-fields>
      </GatGroup>

      <GatGroup
        v-if="applicationStore.user.internal"
        title="Type / role"
        class="pa-0"
        :collapsed="isNew ? !expandedIfNew : true">
        <template slot="compact">
          <v-layout wrap>
            <gat-flex v-if="client.roles && client.roles.length > 0" size="xs12">{{ getRoleDescription() }}</gat-flex>
          </v-layout>
        </template>
        <div v-if="client.ID">
          <v-layout wrap>
            <gat-grid
              :items="client.roles"
              :columns="roleColumns"
              class="elevation-0"
              @row-clicked="roleRowClicked"
              rowHint="click to edit">
            </gat-grid>
          </v-layout>
        </div>
        <v-btn
          v-if="client.ID"
          slot="btn"
          class="addxConsent"
          outlined
          color="primary"
          small
          @click="addRole"
          :disabled="!applicationStore.userRight('C.EDIT')">
          <v-icon small>mdi-plus</v-icon>Add
        </v-btn>
        <div v-else class="pa-3">The new contact have to be saved before this section can be edited</div>
      </GatGroup>

      <gat-bottom-panel
        v-if="true"
        :orgValue="orgClient"
        :newValue="client"
        :itemDescription="'client ' + client.NAME"
        :saveBtn="true"
        :deleteBtn="true"
        :buttonsEnabled="!saving"
        :deleteDisabled="!client.ID || !applicationStore.userRight('C.DELETE')"
        :saveDisabled="!allowSave"
        @saveClicked="saveClicked"
        @deleteClicked="deleteClicked"
        @leftWithoutSaving="leftWithoutSaving"></gat-bottom-panel>

      <client-role-form
        :showForm="showRoleForm"
        v-model="currentRole"
        @close="roleFormClosed"
        :readonly="!applicationStore.userRight('C.EDIT')"></client-role-form>
    </gat-form>
    <br />
  </div>
</template>

<script>
import GatUtils from '@/app-components/GatUtils';
import GatDynaFields from '@/app-components/dynafields/GatDynaFields.vue';
// eslint-disable-next-line import/no-cycle
import { apiPut, apiDelete } from '@/store/api';
import { useConstantsStore } from '@/store/constantsStore';
import { removeFromLastClientUsed } from '@/services/api/api-calls/removeFromLastClientUsed';
import { useApplicationStore } from '@/store/applicationStore';
import { useHelperStore } from '@/store/helperStore';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import GatHeadOfficeSelect from '@/app-components/GatHeadOfficeSelect.vue';
import ClientRoleForm from './ClientRoleForm.vue';
import SednaApiService from '../../../services/api/external/sednaAPI';

export default {
  name: 'ClientMain',
  components: { GatDynaFields, ClientRoleForm, GatHeadOfficeSelect },
  props: {
    value: [Object, Array],
    isNew: Boolean,
  },
  setup() {
    const applicationStore = useApplicationStore();
    const constantsStore = useConstantsStore();
    const helperStore = useHelperStore();
    return {
      helperStore,
      applicationStore,
      constantsStore,
    };
  },
  data() {
    return {
      expandedIfNew: false, // is set in activated/deactivated based on isNew prop.
      client: {},
      currentRole: {},
      orgClient: {},
      saving: false,
      showRoleForm: false,
      isValid: null,
      isLoading: false,
      webModuleInternalAndExternal: 2,
    };
  },
  activated() {
    this.expandedIfNew = this.isNew;
  },
  deactivated() {
    this.expandedIfNew = !this.isNew;
  },

  mounted() {
    // this.client = GatUtils.makeCopyViaJson(this.value);
    // this.orgClient =  GatUtils.makeCopyViaJson(this.client);
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        const value = { ...newValue };
        if (!value.dynaValues) value.dynaValues = [];
        this.client = GatUtils.makeCopyViaJson(value);
        this.orgClient = GatUtils.makeCopyViaJson(this.client);
      },
    },
  },

  computed: {
    agencyIdSelected() {
      return this.applicationStore.agencyIdSelected;
    },
    agencyId() {
      if (this.callId) {
        return this.portcallStore.callDetails.SETUP_ID;
      }
      return this.agencyIdSelected;
    },
    showRankingsInConnectedClients() {
      return this.applicationStore.globalSettings?.SGL_SHOW_FINANCEINFO_HOTLIST;
    },
    roleColumns() {
      const result = [];
      result.push({
        header: 'Type / role',
        calculated: (rowData) =>
          GlobalHelperFunctions.lookupCode(rowData.CLIENT_TYPE_ID, this.clientTypes, 'ID', 'NAME'),
      });
      result.push({ header: 'Harbour', field: 'HarbourName', hideOnPhone: true });
      if (this.showRankingsInConnectedClients) {
        result.push({
          header: 'Ranking',
          hideOnPhone: true,
          calculated: (rowData) =>
            GlobalHelperFunctions.lookupCode(
              rowData.CT_RANKING,
              this.constantsStore.clientTypeRanking,
              'value',
              'text'
            ),
        });
        result.push({ header: 'Date', field: 'CT_RANKING_DATE', hideOnPhone: true, dataType: 'date' });
        result.push({ header: 'Remarks', field: 'CT_COMMENT', hideOnPhone: true });
      }
      return result;
    },
    allowSave() {
      if (this.applicationStore.user.internal) {
        if (this.value && this.value.ID == 0) {
          return this.applicationStore.userRight('C.NEW');
        }
        return this.applicationStore.userRight('C.EDIT');
      }
      if (this.value && this.value.ID === this.applicationStore.user.externalClientId) {
        return this.applicationStore.userRight('OC.EDIT');
      }

      return false;
    },
    countries() {
      return this.helperStore.countries;
    },
    currencies() {
      const result = [];
      const cur = [...this.helperStore.currencies];
      cur.forEach((item) => {
        const curItem = {};
        curItem.value = item.value;
        curItem.text = item.text;
        curItem.ROE = item.ROE;
        // eslint-disable-next-line no-restricted-syntax
        for (const i in item.IDS) {
          if (!curItem.ID) {
            curItem.ID = item.IDS[i];
          }
        }
        result.push(curItem);
      });
      return result;
    },
    clientTypes() {
      return this.helperStore.clientTypes;
    },
    clientCategories() {
      return this.helperStore.clientCategories;
    },
  },

  methods: {
    isRequired(globalSetting, cat0) {
      /* This method takes in 2 arguments. globalSetting should be a string with the name of the global setting to be checked. cat0 is optional if the first setting name is different from the others. */
      // eslint-disable-next-line default-case
      switch (this.client.CATEGORY) {
        case 0: // customer / debitor
          if (this.applicationStore.globalSettings[cat0]) {
            return true;
          }
          if (this.applicationStore.globalSettings[globalSetting]) {
            return true;
          }
          break;
        case 1: // supplier / creditor
          // eslint-disable-next-line no-param-reassign
          globalSetting += '_CAT1';
          if (this.applicationStore.globalSettings[globalSetting]) {
            return true;
          }
          break;
        case 2: // other
          // eslint-disable-next-line no-param-reassign
          globalSetting += '_CAT2';
          if (this.applicationStore.globalSettings[globalSetting]) {
            return true;
          }
      }
      return false;
    },

    addRole() {
      this.currentRole = { CLIENT_ID: this.client.ID };
      this.showRoleForm = true;
    },

    categoryName(catId) {
      if ((catId || 0) >= 0) {
        return this.clientCategories[catId || 0].text;
      }
      return null;
    },

    getRoleDescription() {
      let result = GlobalHelperFunctions.lookupCode(
        this.client.roles[0].CLIENT_TYPE_ID,
        this.clientTypes,
        'ID',
        'NAME'
      );
      for (let index = 1; index < this.client.roles.length; index++) {
        const role = this.client.roles[index];
        result = `${result}, ${GlobalHelperFunctions.lookupCode(role.CLIENT_TYPE_ID, this.clientTypes, 'ID', 'NAME')}`;
      }

      return result;
    },

    roleFormClosed(params) {
      this.showRoleForm = false;
      if (params.btn == 'save') {
        // set isLoading to true to show progressbar if response is not immidiate
        this.isLoading = true;
        apiPut('/clientRole', { value: params.value, orgValue: this.currentRole }).then((result) => {
          // remove progressbar
          this.isLoading = false;
          if (result.resultCategory == 1) {
            this.client.roles = result.payload;
            this.orgClient.roles = this.client.roles; // to avoid save btn "changes"
          }
        });
      } else if (params.btn == 'delete') {
        // set isLoading to true to show progressbar if response is not immidiate
        this.isLoading = true;
        apiDelete(`/clientRole/${params.value.ID}`).then(() => {
          // remove progressbar
          this.isLoading = false;
          const idx = this.client.roles.findIndex((item) => item.ID == params.value.ID);
          this.currentRole = params.value;
          this.client.roles.splice(idx, 1);
          this.orgClient.roles = this.client.roles; // to avoid save btn "changes"
        });
      }
      this.currentRole = {};
    },

    isMultiAddress() {
      const isMultiAddress =
        this.client.ADR_VISIT_STREET ||
        this.client.ADR_VISIT_ZIP ||
        this.client.ADR_VISIT_CITY ||
        this.client.ADR_VISIT_COUNTRY_CODE ||
        this.client.ADR_INVOICE_STREET ||
        this.client.ADR_INVOICE_ZIP ||
        this.client.ADR_INVOICE_CITY ||
        this.client.ADR_INVOICE_COUNTRY_CODE;
      if (isMultiAddress) {
        return 1;
      }
      return 0;
    },

    saveClicked() {
      this.$refs.form.validate();
      if (!this.isValid) return;

      if (!this.client.INACTIVE) this.client.INACTIVE = 0;

      this.client.ADR_MULTI = this.isMultiAddress();

      this.saving = true;
      // set isLoading to true to show progressbar if response is not immidiate
      this.isLoading = true;
      const clientNameChanged = this.client.NAME !== this.orgClient.NAME;
      const clientNumberChanged = this.client.ACCOUNT_NO !== this.orgClient.ACCOUNT_NO;
      apiPut('/clients', {
        value: this.client,
        orgValue: this.orgClient,
        dynavalues: this.client.dynaValues,
        orgDynavalues: this.orgClient.dynaValues,
      }).then((result) => {
        this.saving = false;
        // remove progressbar
        this.isLoading = false;
        if (this.client.ID) {
          window.messageBus.$emit('clientUpdate', this.client);
        } else {
          window.messageBus.$emit('clientInsert', result.payload);
        }
        this.client = result.payload;
        const sednaApi = new SednaApiService(this.applicationStore.agencyIdSelected);
        if ((clientNameChanged || clientNumberChanged) && sednaApi.isSednaIntegration) {
          sednaApi.updateSednaJobIdInSedna('client', this.client.ID, this.client.SEDNA_JOB_ID, []);
        }
        this.orgClient = GatUtils.makeCopyViaJson(this.client);

        this.$nextTick(() => {
          if (this.value.ID == 0) {
            this.$router.replace(`/client/${result.payload.ID}`);
          }
        });
      });
    },

    deleteClicked() {
      this.saving = true;
      // set isLoading to true to show progressbar if response is not immidiate
      this.isLoading = true;
      apiDelete(`/clients/${this.client.ID}`).then((result) => {
        this.saving = false;
        // remove progressbar
        this.isLoading = false;
        window.messageBus.$emit('clientDelete', this.client.ID);
        removeFromLastClientUsed(this.client.ID);
        if (result.resultCategory == 1) {
          // this.$router.go(-1); //go back
          this.$router.push('/clients');
        }
      });
    },

    roleRowClicked(rowData) {
      this.currentRole = rowData;
      this.showRoleForm = true;
    },

    leftWithoutSaving() {
      this.client = GatUtils.makeCopyViaJson(this.value);
      this.orgClient = GatUtils.makeCopyViaJson(this.value);
    },
  },
};
</script>

<style scoped>
.nowrap {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  /* Opera < 11*/
  text-overflow: ellipsis;
}

.compactLabel {
  font-size: 75%;
}
</style>
