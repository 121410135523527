<template>
  <div class="pb-6 mb-6">
    <gat-form v-show="!portcallStore.callDetailsLoading" v-model="isValid" ref="form" :isLoading="isLoading">
      <GatGroup
        class="pa-0"
        :class="getCallStatusBorderClass(lookupCallStatus(portcall.STATUS))"
        title="Port call status"
        :collapsed="$vuetify.breakpoint.xsOnly">
        <template slot="compact">
          <v-layout wrap>
            <gat-compact-field :value="lookupCallStatus(portcall.STATUS)" size="s" label="Status" />
            <gat-compact-field :value="portcall.PORTCALL_NUMBER" size="s" label="Call number" />
            <gat-compact-field
              v-show="portcall.STATUS < 2"
              :value="globalFormatDateTime(portcall.ETA, 'DD.MMM HH:mm')"
              size="s"
              :label="etaCaption" />
            <gat-compact-field
              v-show="portcall.STATUS > 1"
              :value="globalFormatDateTime(portcall.ATA, 'DD.MMM HH:mm')"
              size="s"
              label="ATA" />
            <gat-compact-field
              v-show="portcall.STATUS < 3"
              :value="globalFormatDateTime(portcall.ETD, 'DD.MMM HH:mm')"
              size="s"
              style="min-width: 150px"
              label="ETD" />
            <gat-compact-field
              v-show="portcall.STATUS >= 3"
              :value="globalFormatDateTime(portcall.ATD, 'DD.MMM HH:mm')"
              size="s"
              label="ATD" />
          </v-layout>
        </template>
        <v-layout wrap>
          <!-- store:{{storePortcall}} -->
          <GatSubGroup size="xs12 md7">
            <gat-flex size="x12 sm4 md4">
              <gat-field-spacer>
                <v-tooltip bottom :nudge-bottom="-20">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="!userCanChangeSailed ? on : undefined" v-bind="!userCanChangeSailed ? attrs : undefined">
                      <GatSelect
                        label="Status"
                        noSearch
                        v-model="portcall.STATUS"
                        :items="callStatus"
                        noflex
                        :useIcons="true"
                        textFieldName="text"
                        :readonly="!userCanEditCall || portcallStore.expenseLocked" />
                    </div>
                  </template>
                  <div style="max-width: 200px">
                    It's more than 48 hours since this port call has been set to sailed, so it's not allowed to set
                    status back to Expected, Anchored or Berthed
                  </div>
                </v-tooltip>
              </gat-field-spacer>
            </gat-flex>
            <GatEdit
              label="Call number"
              v-model="portcall.PORTCALL_NUMBER"
              :max-chars="applicationStore.getColumnLength('PortCall.PORTCALL_NUMBER')"
              size="xs12 sm4 md4"
              :readonly="!applicationStore.user.internal" />
            <GatSelect
              label="Type of call"
              v-model="portcall.extra.PCE_CALLTYPE"
              :items="helperStore.callTypes"
              :readonly="!applicationStore.user.internal"
              size="xs12 sm4 md4" />

            <gat-edit
              v-show="portcall.STATUS === 0 && showEtpsField"
              :label="etpsCaption"
              v-model="portcall.extra.ETPS"
              dateTimeEdit
              futureDate
              :hint="etpsCaption === 'ETPS' ? 'Estimated time of pilot station' : undefined"
              size="xs12 sm6 md6 lg5 xl4"
              :required="applicationStore.globalSettings.SGL_ETPS_MANDATORY === 1" />

            <GatEdit
              :label="etaCaption === 'ETA' ? etaCaption + ' date' : etaCaption"
              v-show="portcall.STATUS < 2"
              v-model="portcall.ETA"
              dateTimeEdit
              futureDate
              hint="Estimated time of arrival"
              size="xs12 sm6 md6 lg5 xl4"
              :readonly="!userCanEditCall" />
            <GatEdit
              label="ATA date"
              v-show="portcall.STATUS > 1"
              v-model="portcall.ATA"
              dateTimeEdit
              hint="Actual time of arrival"
              size="xs12 sm6 md6 lg5 xl4"
              :readonly="!userCanEditCall" />
            <GatEdit
              label="ETD date"
              v-show="portcall.STATUS < 3"
              v-model="portcall.ETD"
              dateTimeEdit
              futureDate
              hint="Estimated time of departure"
              size="xs12 sm6 md6 lg5 xl4"
              :disabled="orgPortcall.BERTH_CALCULATION === 1"
              :readonly="!userCanEditCall" />
            <GatEdit
              label="ATD date"
              v-show="portcall.STATUS > 2"
              v-model="portcall.ATD"
              dateTimeEdit
              hint="Actual time of departure"
              size="xs12 sm6 md6 lg5 xl4"
              :readonly="!userCanEditCall" />
            <GatEdit
              v-if="!$vuetify.breakpoint.lgAndDown"
              label="Show all dates"
              v-model="showAllTimes"
              toggle
              :readonly="false"
              size="md4 lg2 xl4" />
            <GatSubGroup v-show="showAllTimes" size="xs12">
              <gat-edit
                v-show="portcall.STATUS !== 0 && showEtpsField"
                :label="etpsCaption"
                v-model="portcall.extra.ETPS"
                dateTimeEdit
                futureDate
                :hint="etpsCaption === 'ETPS' ? 'Estimated time of pilot station' : undefined"
                size="xs12 sm6 md6 lg5 xl4"
                :required="applicationStore.globalSettings.SGL_ETPS_MANDATORY === 1" />
              <GatEdit
                :label="etaCaption"
                v-show="!(portcall.STATUS < 2)"
                v-model="portcall.ETA"
                dateTimeEdit
                futureDate
                hint="Estimated time of arrival"
                size="xs12 sm6 md6 lg5 xl4"
                :readonly="!userCanEditCall" />
              <GatEdit
                label="ATA"
                v-show="!(portcall.STATUS > 1)"
                v-model="portcall.ATA"
                dateTimeEdit
                hint="Actual time of arrival"
                size="xs12 sm6 md6 lg5 xl4"
                :readonly="!userCanEditCall" />
              <GatEdit
                label="ETD"
                v-show="!(portcall.STATUS < 3)"
                v-model="portcall.ETD"
                dateTimeEdit
                futureDate
                :disabled="orgPortcall.BERTH_CALCULATION === 1"
                hint="Estimated time of departure"
                size="xs12 sm6 md6 lg5 xl4"
                :readonly="!userCanEditCall" />
              <GatEdit
                label="ATD"
                v-show="!(portcall.STATUS > 2)"
                v-model="portcall.ATD"
                dateTimeEdit
                hint="Actual time of departure"
                size="xs12 sm6 md6 lg5 xl4"
                :readonly="!userCanEditCall" />
            </GatSubGroup>
            <GatEdit
              v-if="$vuetify.breakpoint.lgAndDown"
              label="Show all dates"
              v-model="showAllTimes"
              toggle
              :readonly="false"
              size="xs12 sm12 md12" />
          </GatSubGroup>

          <GatFlex size="xs12 md5" class="d-flex align-center justify-center">
            <vessel-current-a-i-s :imo="vessel.LLOYDSNR" :portcallStatus="portcall.STATUS" class="ais" />
            <!-- <GatSubGroup v-if="!this.$vuetify.breakpoint.xs" title="" mclass="mb-1"> -->
            <div v-if="!this.$vuetify.breakpoint.xs" class="pl-4">
              <v-img
                :src="'https://webservice.vesseltracker.com/shipPhotoServlet?res=full&imo=' + vessel.LLOYDSNR"
                class="vesselImg"
                contain></v-img>
            </div>
            <!-- </GatSubGroup> -->
          </GatFlex>
        </v-layout>
      </GatGroup>
      <GatGroup title="From / to" :collapsed="true">
        <template slot="compact">
          <v-layout wrap>
            <gat-compact-field :value="prevPortName" size="xs12 sm3 lg2" label="Previous port" />
            <gat-compact-field
              :value="portcall.extra.PCE_ETDPP"
              size="xs12 sm2 lg2"
              hide="phone"
              label="ETD previous port"
              is-date-time />
            <gat-compact-field :value="nextPortName" size="xs12 sm3 lg2" label="Next port" />
            <gat-compact-field
              :value="portcall.extra.PCE_EDANP"
              size="xs12 sm2 lg2"
              hide="phone"
              label="ETA next port"
              is-date-time />
            <gat-compact-field
              :value="portcall.extra.PCE_ETDNP"
              size="xs12 sm2 lg2"
              hide="phone"
              label="ETD next port"
              is-date-time />
          </v-layout>
        </template>
        <v-layout wrap>
          <GatSubGroup size="xs12">
            <GatHarbourSelect
              label="Previous port"
              v-model="portcall.prevPort"
              size="xs6 sm4 md3"
              :readonly="!userCanEditCall"
              @item-selected="(val) => setPortName(val, 'prev')" />
            <GatEdit
              label="ETD prev port"
              v-model="portcall.extra.PCE_ETDPP"
              dateTimeEdit
              futureDate
              :readonly="!userCanEditCall" />
            <GatHarbourSelect
              label="Next port"
              v-model="portcall.nextPort"
              size="xs6 sm4 md3"
              :readonly="!userCanEditCall"
              @item-selected="(val) => setPortName(val, 'next')" />
            <GatEdit
              label="ETA next port"
              v-model="portcall.extra.PCE_EDANP"
              dateTimeEdit
              futureDate
              :readonly="!userCanEditCall" />
            <GatEdit
              label="ETD next port"
              v-model="portcall.extra.PCE_ETDNP"
              dateTimeEdit
              futureDate
              :readonly="!userCanEditCall" />
          </GatSubGroup>
        </v-layout>
      </GatGroup>

      <PortcallVesselDetails v-show="!$vuetify.breakpoint.xsOnly" :vessel="vessel" />
      <PortCallBerthAnchorage
        v-model="portcall"
        :readonly="!userCanEditCall"
        @calculate-berth-clicked="onCalculateBerthClicked" />
      <!-- <record-presenter :value="portcall" /> -->

      <GatGroup title="Details" :collapsed="true">
        <template slot="compact">
          <v-layout wrap>
            <gat-compact-field :value="portcall.VOY" size="s" label="Voyage no" />
            <gat-compact-field :value="portcall.CARGO_NO" size="s" hide="phone" label="Cargo no" />
            <gat-compact-field :value="invoiceStatusText" size="s" label="D.A. / Invoice status" />
            <gat-compact-field :value="portcall.MASTER_NAME" size="s" label="Master name" />
            <gat-compact-field :value="portcall.MASTER_CERTIFICATE" size="s" hide="phone" label="Master certificate" />
            <gat-compact-field :value="portcall.extra.PCE_SEQURITY_LEVEL" size="s" hide="phone" label="ISPS level" />
            <gat-compact-field
              :value="portcall.extra.PCE_PORT_SEQURITY_OFFICER"
              size="s"
              hide="phone"
              label="ISPS PFSO" />
            <gat-compact-field
              :value="portcall.extra.PCE_VESSEL_SEQURITY_OFFICER"
              size="s"
              hide="phone"
              label="ISPS SSO" />
            <gat-compact-field
              :value="portcall.USERDEF_1"
              size="s"
              hide="phone"
              :label="agencySettings.PORTCALL_USER1"
              v-if="applicationStore.user.internal && agencySettings.PORTCALL_USER1" />
            <gat-compact-field
              :value="portcall.USERDEF_2"
              size="s"
              hide="phone"
              :label="agencySettings.PORTCALL_USER2 && agencySettings.PORTCALL_USER2"
              v-if="applicationStore.user.internal" />
            <gat-compact-field
              :value="portcall.extra.PCE_USERDEF_3"
              size="s"
              hide="phone"
              :label="agencySettings.PORTCALL_USER3"
              v-if="applicationStore.user.internal && agencySettings.PORTCALL_USER3" />
            <gat-compact-field
              :value="portcall.extra.PCE_USERDEF_4"
              size="s"
              hide="phone"
              :label="agencySettings.PORTCALL_USER4"
              v-if="applicationStore.user.internal && agencySettings.PORTCALL_USER4" />
            <gat-compact-field
              :value="portcall.extra.PCE_USERDEF_5"
              size="s"
              hide="phone"
              :label="agencySettings.PORTCALL_USER5"
              v-if="applicationStore.user.internal && agencySettings.PORTCALL_USER5" />

            <gat-compact-field :value="lookupUserName(portcall.PERSON_IN_CHARGE)" size="s" label="Person in charge" />
            <gat-compact-field :value="lookupTeamName(portcall.TEAM_IN_CHARGE_ID)" size="s" label="Team in charge" />
          </v-layout>
        </template>
        <v-layout wrap>
          <GatSubGroup size="xs12">
            <GatEdit
              label="Voyage no"
              v-model="portcall.VOY"
              size="xl2 lg2 sm4 xs6"
              :maxChars="applicationStore.getColumnLength('PortCall.VOY')"
              :readonly="!userCanEditCall" />
            <GatEdit
              label="Cargo no"
              v-model="portcall.CARGO_NO"
              size="xl2 lg2 sm4 xs6"
              :maxChars="applicationStore.getColumnLength('PortCall.CARGO_NO')"
              :readonly="!userCanEditCall" />
            <gat-select
              v-model="portcall.INVOICE_STATUS"
              :items="invoiceStatus"
              size="xl3 lg4 sm4 xs12"
              label="D.A. / Invoice"
              :readonly="!applicationStore.user.internal || portcallStore.expenseLocked"
              :hint="portcallStore.expenseLocked ? 'Expenses locked' : undefined" />
          </GatSubGroup>
          <GatSubGroup size="xs12">
            <GatEdit
              label="Master name"
              v-model="portcall.MASTER_NAME"
              size="xl2 lg2 sm4 xs6"
              :maxChars="applicationStore.getColumnLength('PortCall.MASTER_NAME')"
              :readonly="!userCanEditCall" />
            <GatEdit
              label="Master certificate"
              v-model="portcall.MASTER_CERTIFICATE"
              size="xl2 lg2 sm4 xs6"
              :maxChars="applicationStore.getColumnLength('PortCall.MASTER_CERTIFICATE')"
              :readonly="!userCanEditCall" />
            <gat-select
              label="ISPS level"
              v-model="portcall.extra.PCE_SEQURITY_LEVEL"
              :items="[
                { value: 0, text: '' },
                { value: 1, text: '1' },
                { value: 2, text: '2' },
                { value: 3, text: '3' },
                { value: 4, text: '4' },
              ]"
              size="xl1 lg2 sm4 xs6"
              :readonly="!userCanEditCall" />
            <GatEdit
              label="ISPS PFSO"
              v-model="portcall.extra.PCE_PORT_SEQURITY_OFFICER"
              size="xl2 lg2 sm4 xs6"
              hint="Port facility security officer"
              :maxChars="applicationStore.getColumnLength('PortCall_Extra.PCE_PORT_SEQURITY_OFFICER')"
              :readonly="!userCanEditCall" />
            <gat-select
              label="ISPS SSO"
              v-model="portcall.extra.PCE_VESSEL_SEQURITY_OFFICER"
              :items="['Master', 'C/O']"
              size="xl2 lg2 sm4 xs6"
              :combobox="true"
              hint="Ship security officer"
              :readonly="!userCanEditCall" />
          </GatSubGroup>
          <GatSubGroup size="xs12">
            <GatEdit
              :label="agencySettings.PORTCALL_USER1"
              v-model="portcall.USERDEF_1"
              size="xl2 lg2 sm4 xs6"
              v-if="applicationStore.user.internal && agencySettings.PORTCALL_USER1"
              :maxChars="applicationStore.getColumnLength('PortCall.USERDEF_1')"
              :readonly="!userCanEditCall" />
            <GatEdit
              :label="agencySettings.PORTCALL_USER2"
              v-model="portcall.USERDEF_2"
              size="xl2 lg2 sm4 xs6"
              v-if="applicationStore.user.internal && agencySettings.PORTCALL_USER2"
              :maxChars="applicationStore.getColumnLength('PortCall.USERDEF_2')"
              :readonly="!userCanEditCall" />
            <GatEdit
              :label="agencySettings.PORTCALL_USER3"
              v-model="portcall.extra.PCE_USERDEF_3"
              size="xl2 lg2 sm4 xs6"
              v-if="applicationStore.user.internal && agencySettings.PORTCALL_USER3"
              :maxChars="applicationStore.getColumnLength('PortCall_Extra.PCE_USERDEF_3')"
              :readonly="!userCanEditCall" />
            <GatEdit
              :label="agencySettings.PORTCALL_USER4"
              v-model="portcall.extra.PCE_USERDEF_4"
              size="xl2 lg2 sm4 xs6"
              v-if="applicationStore.user.internal && agencySettings.PORTCALL_USER4"
              :maxChars="applicationStore.getColumnLength('PortCall_Extra.PCE_USERDEF_4')"
              :readonly="!userCanEditCall" />
            <GatEdit
              :label="agencySettings.PORTCALL_USER5"
              v-model="portcall.extra.PCE_USERDEF_5"
              size="xl2 lg2 sm4 xs6"
              v-if="applicationStore.user.internal && agencySettings.PORTCALL_USER5"
              :maxChars="applicationStore.getColumnLength('PortCall_Extra.PCE_USERDEF_5')"
              :readonly="!userCanEditCall" />
          </GatSubGroup>
          <GatSubGroup size="xs12">
            <ResponsibleRadioInput
              :portcall-setup-id="portcall.SETUP_ID"
              :teamIsResponsible.sync="teamIsResponsible"
              :personInCharge.sync="portcall.PERSON_IN_CHARGE"
              :teamInCharge.sync="portcall.TEAM_IN_CHARGE_ID"
              :readonly="!applicationStore.user.internal"
              radio-size="xl2 lg2 sm4 xs12"
              select-size="xl2 lg4 sm4 xs12" />
            <!-- <GatEdit label="Team responsible" v-model="teamIsResponsible" toggle size="xl2 lg2 sm4 xs6" style="min-width: 200px;" :readonly="!applicationStore.user.internal" />
             <gat-select v-if="!teamIsResponsible" label="Person in charge" size="xl2 lg2 sm4 xs6" v-model="portcall.PERSON_IN_CHARGE" :items=users textFieldName="navn" codeFieldName="KODE" :readonly="!applicationStore.user.internal"  />
             <gat-select v-if="teamIsResponsible" label="Team in charge" size="xl2 lg2 sm4 xs6" v-model="portcall.TEAM_IN_CHARGE_ID" :items=teams :readonly="!applicationStore.user.internal"  /> -->
          </GatSubGroup>

          <!-- <gat-flex size = "s" class="ml-2">
           <v-radio-group v-model="inChargeType"  label="Responsible">
             <v-radio label="User" value="user"></v-radio>
             <v-radio label="Team" value="team"></v-radio>
           </v-radio-group>
         </gat-flex> -->
        </v-layout>
      </GatGroup>

      <GatGroup
        class="pa-0"
        title="Remarks"
        :collapsed="false"
        v-if="canViewRemarks1 || canViewRemarks2 || canViewRemarks3">
        <template slot="compact">
          <v-layout class="pr-3" wrap>
            <gat-compact-field
              v-if="canViewRemarks1"
              v-model="portcall.REMARKS"
              :label="applicationStore.globalSettings.SGL_REMARK1_CAPTION"
              size="xs12"
              :rows="remarksCompactRows(portcall.REMARKS)" />
            <gat-compact-field
              v-if="applicationStore.globalSettings.SGL_REMARK2_CAPTION && canViewRemarks2"
              v-model="portcall.extra.PCE_REMARKS2"
              :label="applicationStore.globalSettings.SGL_REMARK2_CAPTION"
              size="xs12"
              :rows="remarksCompactRows(portcall.extra.PCE_REMARKS2)" />
            <gat-compact-field
              v-if="applicationStore.globalSettings.SGL_REMARK3_CAPTION && canViewRemarks3"
              v-model="portcall.extra.PCE_REMARKS3"
              :label="applicationStore.globalSettings.SGL_REMARK3_CAPTION"
              size="xs12"
              :rows="remarksCompactRows(portcall.extra.PCE_REMARKS3)" />
          </v-layout>
        </template>
        <v-layout wrap>
          <GatSubGroup title="" size="xs12">
            <div
              v-if="canViewRemarks1"
              style="width: 100%; height: auto; position: relative"
              @mouseover="showInsertInitialsBtn.remark = true"
              @mouseleave="showInsertInitialsBtn.remark = false">
              <v-btn
                v-if="userCanEditCall && showInsertInitialsBtn.remark"
                class="ml-3 initials-btn"
                small
                outlined
                color="primary"
                @click="insertInitials('REMARKS')"
                >Insert initials</v-btn
              >
              <gat-edit
                v-model="portcall.REMARKS"
                :label="applicationStore.globalSettings.SGL_REMARK1_CAPTION"
                ref="remarks"
                size="xs12"
                :rows="remarksCompactRows(portcall.REMARKS)"
                :readonly="!userCanEditCall"
                :maxChars="0" />
            </div>
            <div
              v-if="canViewRemarks2"
              style="width: 100%; height: auto; position: relative"
              @mouseover="showInsertInitialsBtn.remark2 = true"
              @mouseleave="showInsertInitialsBtn.remark2 = false">
              <v-btn
                v-if="userCanEditCall && showInsertInitialsBtn.remark2"
                class="ml-3 initials-btn"
                small
                outlined
                color="primary"
                @click="insertInitials('PCE_REMARKS2')"
                >Insert initials</v-btn
              >
              <gat-edit
                v-model="portcall.extra.PCE_REMARKS2"
                ref="remarks2"
                :label="applicationStore.globalSettings.SGL_REMARK2_CAPTION"
                size="xs12"
                :rows="remarksCompactRows(portcall.extra.PCE_REMARKS2)"
                :readonly="!userCanEditCall"
                :maxChars="0" />
            </div>
            <div
              v-if="canViewRemarks3"
              style="width: 100%; height: auto; position: relative"
              @mouseover="showInsertInitialsBtn.remark3 = true"
              @mouseleave="showInsertInitialsBtn.remark3 = false">
              <v-btn
                v-if="userCanEditCall && showInsertInitialsBtn.remark3"
                class="ml-3 initials-btn"
                small
                outlined
                color="primary"
                @click="insertInitials('PCE_REMARKS3')"
                >Insert initials</v-btn
              >
              <gat-edit
                v-model="portcall.extra.PCE_REMARKS3"
                ref="remarks3"
                :label="applicationStore.globalSettings.SGL_REMARK3_CAPTION"
                size="xs12"
                :rows="remarksCompactRows(portcall.extra.PCE_REMARKS3)"
                :readonly="!userCanEditCall"
                :maxChars="0" />
            </div>
          </GatSubGroup>
        </v-layout>
      </GatGroup>
      <div v-for="(tab, index) in dynaWebTabs" :key="index">
        <GatGroup class="pa-0" :title="tab.DWT_CAPTION" :collapsed="false">
          <template slot="compact">
            <gat-dyna-fields
              v-model="portcall.dynaValues"
              componentName="tbUserDefTab.MainDyna"
              container="portcallDetails"
              :tabId="tab.DWT_ID"
              :compact="true" />
          </template>
          <v-layout wrap>
            <GatSubGroup title="" size="xs12">
              <gat-dyna-fields
                v-model="portcall.dynaValues"
                componentName="tbUserDefTab.MainDyna"
                container="portcallDetails"
                :tabId="tab.DWT_ID"
                :readonly="!userCanEditCall"
                :portcallStatus="portcall.STATUS" />
            </GatSubGroup>
          </v-layout>
        </GatGroup>
      </div>
    </gat-form>
    <duplicate-port-call
      v-if="dialogVisible && duplicateParams"
      :showForm="dialogVisible"
      :duplicateParams="duplicateParams"
      :portcall="duplicatePortcall"
      @close="closeDuplicateDialog" />
    <gat-bottom-panel
      :orgValue="orgPortcall"
      :newValue="portcall"
      itemDescription="this port call"
      refreshBtn
      @refreshClicked="getPortcallDetails"
      saveBtn
      :buttonsEnabled="!(saving || isLoading)"
      :deleteDisabled="true"
      @saveClicked="saveClicked"
      @deleteClicked="deleteClicked">
      <template slot="append-left-buttons">
        <v-btn v-if="canCreatePortCall && !saving && !valueChanged" text class="ml-3 right" @click="showDuplicateForm"
          >Duplicate</v-btn
        >
      </template>
    </gat-bottom-panel>
  </div>
</template>

<script>
import GatUtils from '@/app-components/GatUtils';
import GatHarbourSelect from '@/app-components/GatHarbourSelect.vue';
import VesselCurrentAIS from '@/views/vessels/VesselCurrentAIS.vue';
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPut } from '@/store/api';
import GatDynaFields from '@/app-components/dynafields/GatDynaFields.vue';
import { DateTime } from 'luxon';
import { useConstantsStore } from '@/store/constantsStore';
import { useApplicationStore } from '@/store/applicationStore';
import { usePortcallStore } from '@/store/portcallStore';
import { useHelperStore } from '@/store/helperStore';
import { getPortcallDetails } from '@/services/api/api-calls/getPortcallDetails';
import { getBerths } from '@/services/api/api-calls/getBerths';
import { getBerthedPortcalls } from '@/services/api/api-calls/getBerthedPortcalls';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import PortcallVesselDetails from './PortcallVesselDetails.vue';
import PortCallBerthAnchorage from './PortCallBerthAnchorage.vue';
import ResponsibleRadioInput from './ResponsibleRadioInput.vue';
import DuplicatePortCall from './DuplicatePortCall.vue';
import SednaApiService from '../../services/api/external/sednaAPI';

export default {
  name: 'PortCallDetails',
  props: ['value', 'accessGroup', 'callId'],
  components: {
    PortcallVesselDetails,
    VesselCurrentAIS,
    GatHarbourSelect,
    PortCallBerthAnchorage,
    GatDynaFields,
    ResponsibleRadioInput,
    DuplicatePortCall,
  },
  setup() {
    const constantsStore = useConstantsStore();
    const applicationStore = useApplicationStore();
    const portcallStore = usePortcallStore();
    const helperStore = useHelperStore();
    return {
      helperStore,
      constantsStore,
      applicationStore,
      portcallStore,
    };
  },
  data() {
    return {
      dialogVisible: false,
      teamIsResponsible: false,
      portcall: { empty: true, extra: {} },
      duplicatePortcall: {},
      duplicateParams: null,
      vessel: {},
      orgPortcall: {},
      prevPortName: '',
      nextPortName: '',
      showAllTimes: false,
      saving: false,
      isNew: false,
      isValid: false,
      isLoading: false,
      showInsertInitialsBtn: {
        remark: false,
        remark2: false,
        remark3: false,
      },
    };
  },

  mounted() {
    this.dialogVisible = false;
  },

  created() {},

  computed: {
    showEtpsField() {
      return this.agencySettings.ETPS_HIDE !== 1;
    },
    etaCaption() {
      return this.agencySettings.ETA_CAPTION ?? 'ETA';
    },
    etpsCaption() {
      return this.agencySettings.ETPS_CAPTION ?? 'ETPS';
    },
    valueChanged() {
      const objectChanges = GlobalHelperFunctions.getObjectChanges(this.orgPortcall, this.portcall);
      const filteredChanges = objectChanges.filter((change) => {
        // empty string = null = undefined
        if (
          (change.newValue === '""' || change.newValue === 'null' || change.newValue === 'undefined') &&
          (change.oldValue === '""' || change.oldValue === 'null' || change.oldValue === 'undefined')
        ) {
          return false;
        }
        return true;
      });

      const valChanged = filteredChanges.length > 0;
      return valChanged;
    },
    canCreatePortCall() {
      return !this.saving && this.applicationStore.userRight('P.NEW_AND_EDIT') && this.applicationStore.user.internal;
    },
    canViewRemarks1() {
      if (this.applicationStore.user.internal) {
        return true;
      }
      if (this.applicationStore.userRight('CR.VIEW_REMARKS1')) {
        return true;
      }
      return false;
    },

    canViewRemarks2() {
      if (this.applicationStore.globalSettings.SGL_REMARK2_CAPTION) {
        if (this.applicationStore.user.internal) {
          return true;
        }
        if (this.applicationStore.userRight('CR.VIEW_REMARKS2')) {
          return true;
        }
      }
      return false;
    },

    canViewRemarks3() {
      if (this.applicationStore.globalSettings.SGL_REMARK3_CAPTION) {
        if (this.applicationStore.user.internal) {
          return true;
        }
        if (this.applicationStore.userRight('CR.VIEW_REMARKS3')) {
          return true;
        }
      }
      return false;
    },

    storePortcall() {
      return this.portcallStore.callDetails;
    },

    agencySettings() {
      return this.applicationStore.getAgencySettings(this.portcall.SETUP_ID);
    },

    userCanChangeSailed() {
      if (this.portcallStore.callDetails.sailedTime) {
        const end = DateTime.now();
        const start = DateTime.fromISO(this.portcallStore.callDetails.sailedTime);
        if (start.isValid) {
          const diffInMinutes = end.diff(start, 'minutes');
          if (diffInMinutes.toObject().minutes >= 2880) {
            return false;
          }
        }
      }
      return true;
    },

    callStatus() {
      const result = [...this.constantsStore.portcallStatus]; // Make a copy of the state.
      // if the user doesnt have the rights to cancle a portcall, remove the option.
      if (!this.userCanCancelCall && this.portcall.STATUS != 4) {
        const idx = result.findIndex((item) => item.value == 4);
        result.splice(idx, 1);
      }
      // if the user doesnt have the rights to archive a portcall, remove the option.
      if (!this.userCanArchiveCall && this.portcall.STATUS != 5) {
        const idx = result.findIndex((item) => item.value == 5);
        result.splice(idx, 1);
      }
      if (!this.userCanChangeSailed) {
        const newResult = [];
        result.forEach((item) => {
          const newItem = { ...item };
          if (newItem.value === 3 || newItem.value === 5) {
            // Can only change to archived
            newResult.push(newItem);
          }
        });
        return newResult;
      }
      return result;
    },

    teams() {
      return this.helperStore.teams(this.portcall.SETUP_ID);
    },

    users() {
      return this.helperStore.users;
    },

    userCanCancelCall() {
      const { canCancel } = this.storePortcall;
      return (
        canCancel &&
        (this.applicationStore.user.internal
          ? this.applicationStore.userRight('P2.CANCEL')
          : this.applicationStore.userRight('P.CANCEL'))
      );
    },

    userCanArchiveCall() {
      return this.applicationStore.user.internal ? true : this.applicationStore.userRight('P.ARCHIVE');
    },

    userCanEditCall() {
      if (this.portcall.STATUS === 4) {
        return this.applicationStore.userRight('P.EDIT') && this.userCanCancelCall;
      }
      if (this.portcall.STATUS === 5) {
        return this.applicationStore.userRight('P.EDIT') && this.userCanArchiveCall;
      }
      return this.applicationStore.userRight('P.EDIT');
    },

    // getStatusClass(){
    //   return "call-status-"+this.lookupCallStatus(this.portcall.STATUS);
    // },

    dynaWebTabs() {
      const result = []; // result will return a tab and dynavalues if any dyna fields are found.
      const { dynafields } = this.helperStore; // store containing all dyna fields
      let tabs = this.helperStore.dynaWebTabs; // store containing all web tabs
      tabs = tabs.filter(
        (t) =>
          (t.agencyFilter && t.agencyFilter.length > 0 && t.agencyFilter.includes(this.portcall.SETUP_ID)) ||
          (t.agencyFilter && t.agencyFilter.length === 0)
      );
      tabs.forEach((tab) => {
        // loop through all tabs in the store
        let hasDynaValue = false;
        dynafields.forEach((dyna) => {
          // loop through all dyna fields in each tab.
          if (
            tab.DWT_ID == dyna.WEB_TAB_PAGE_ID &&
            dyna.COMPONENT_NAME == 'tbUserDefTab.MainDyna' &&
            dyna.WEB_USE_IN_WEBMODULE == 1
          ) {
            // check that the ID for the tab matches the ID for the dyna web tab.
            if (this.applicationStore.user.internal) {
              hasDynaValue = true;
            } else if (!this.applicationStore.user.internal && dyna.WEB_EXTERNAL_USER_ACCESS != 0) {
              // extra check for external users. Will remove the tab alltogether if no dynafields are valid.
              hasDynaValue = true;
            }
          }
        });
        if (hasDynaValue) {
          result.push(tab); // if the tab has dyna fields: push the tab to the result.
        }
      });
      return result;
    },

    invoiceStatus() {
      return this.constantsStore.invoiceStatus;
    },

    invoiceStatusText() {
      return GlobalHelperFunctions.lookupCode(this.portcall.INVOICE_STATUS, this.invoiceStatus);
    },
  },

  watch: {
    storePortcall: {
      handler(newValue) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        newValue.TEAM_IN_CHARGE_ID ? (this.teamIsResponsible = true) : (this.teamIsResponsible = false);
        this.portcall = GatUtils.makeCopyViaJson(newValue);
        this.orgPortcall = GatUtils.makeCopyViaJson(this.portcall);
        this.vessel = newValue.vessel;
        if (!this.vessel) {
          this.vessel = {};
        }
      },
      immediate: true,
    },
  },

  methods: {
    globalFormatDateTime(date, format) {
      return GlobalHelperFunctions.globalFormatDateTime(date, format);
    },
    onCalculateBerthClicked(newValue) {
      this.portcall.BERTH_CALCULATION = newValue;
      this.saveClicked();
    },
    setPortName(value, prevOrNext) {
      const textValue = value.NAME;
      if (prevOrNext === 'prev') {
        this.prevPortName = textValue;
      } else if (prevOrNext === 'next') {
        this.nextPortName = textValue;
      }
    },
    remarksCompactRows(str) {
      if (str) {
        const result = str.split(/\r\n|\r|\n/).length + 2;
        if (result > 5) {
          return result;
        }
      }
      return 5;
    },

    setPortcallOnce(call) {
      if (this.portcall.empty) {
        this.portcall = GatUtils.makeCopyViaJson(call);
      }
    },

    combineDateAndTime(date, time) {
      return GatUtils.combineDateAndTime(date, time);
    },

    deleteClicked() {
      // console.log(this.portcall);
    },

    getCallStatusBorderClass(statusName) {
      let result = '';
      if (statusName) {
        result = `border-status-${statusName.toLowerCase()}`;
      }
      return result;
    },

    lookupCallStatus(code) {
      return GlobalHelperFunctions.lookupCode(code, this.constantsStore.portcallStatus);
    },

    lookupTeamName(teamId) {
      return GlobalHelperFunctions.lookupCode(teamId, this.teams);
    },

    lookupUserName(userCode) {
      return GlobalHelperFunctions.lookupCode(userCode, this.users, 'KODE', 'navn');
    },

    getDatePart(dateTime) {
      if (dateTime == null || dateTime == '') return null;

      // Get date part of a date time string (dd-mm-yyyyThh:nn)
      let result = dateTime.split('T')[0];

      if (result == 'null') result = null;

      return result;
    },

    getTimePart(dateTime) {
      if (dateTime == null || dateTime == '') return null;

      // Get time part of a date time string (dd-mm-yyyyThh:nn)
      let result = dateTime.split('T')[1];

      if (result == 'null' || result == null) {
        result = null;
      }

      return result;
    },

    insertInitials(key) {
      const today = GlobalHelperFunctions.globalFormatDateTime(new Date(), 'DD.MM HH:mm');
      let text;
      let lineBreak = '\n';
      if (key == 'PCE_REMARKS2' || key == 'PCE_REMARKS3') {
        text = this.portcall.extra[key] || '';
        if (text.length > 0) {
          lineBreak = '\n \n';
        }
        this.portcall.extra[key] = `${this.applicationStore.user.userName} ${today}${lineBreak}${text}`;
      } else {
        text = this.portcall[key] || '';
        if (text.length > 0) {
          lineBreak = '\n \n';
        }
        this.portcall[key] = `${this.applicationStore.user.userName} ${today}${lineBreak}${this.portcall[key]}`;
      }
      let refName = key.toLowerCase();
      if (key.includes('_')) {
        const splitted = key.split('_');
        refName = splitted[1].toLowerCase();
      }
      const cursorPosition = `${this.applicationStore.user.userName} ${today}`;
      this.setFocusToInput(refName, cursorPosition.length + 1);
    },

    setFocusToInput(ref, cursorPosition) {
      const item = this.$refs[ref].$refs.field.$refs.input;
      item.focus();
      if (cursorPosition) {
        this.$nextTick(() => {
          item.setSelectionRange(cursorPosition, cursorPosition);
        });
      }
    },

    async getPortcallDetails() {
      this.isLoading = true;
      await getPortcallDetails(this.callId);
      this.isLoading = false;
    },

    async showDuplicateForm() {
      this.isLoading = true;
      this.duplicateParams = await apiGet('portcall/duplicate-portcall-parameters');
      this.duplicatePortcall = GatUtils.makeCopyViaJson(this.portcall);
      this.isLoading = false;
      this.dialogVisible = true;
    },

    closeDuplicateDialog() {
      this.duplicateParams = null;
      this.dialogVisible = false;
    },

    async saveClicked() {
      this.$refs.form.validate();
      if (this.isValid) {
        // Convert combined date and time to separate date and time parts
        this.portcall.ETA_TIME = this.getTimePart(this.portcall.ETA);
        this.portcall.ETA_DATE = this.getDatePart(this.portcall.ETA);
        this.portcall.ETD_TIME = this.getTimePart(this.portcall.ETD);
        this.portcall.ETD_DATE = this.getDatePart(this.portcall.ETD);
        this.portcall.ATA_TIME = this.getTimePart(this.portcall.ATA);
        this.portcall.ATA_DATE = this.getDatePart(this.portcall.ATA);
        this.portcall.ATD_TIME = this.getTimePart(this.portcall.ATD);
        this.portcall.ATD_DATE = this.getDatePart(this.portcall.ATD);
        this.portcall.extra.PCE_ETPS_TIME = this.getTimePart(this.portcall.extra.ETPS);
        this.portcall.extra.PCE_ETPS_DATE = this.getDatePart(this.portcall.extra.ETPS);

        if (this.orgPortcall.STATUS !== this.portcall.STATUS) {
          if (this.orgPortcall.STATUS !== 4 && this.portcall.STATUS === 4) {
            // We are cancelling a port call

            const currDateTime = GlobalHelperFunctions.globalFormatDate(Date.now(), 'YYYY-MM-DDTHH:mm:ss');

            if (!this.portcall.CANCEL_TIME_DATE) {
              this.portcall.CANCEL_TIME_DATE = this.getDatePart(currDateTime);
            }
            if (!this.portcall.CANCEL_TIME_TIME) {
              this.portcall.CANCEL_TIME_TIME = this.getTimePart(currDateTime);
            }
            if (!this.portcall.CANCEL_BY) {
              this.portcall.CANCEL_BY = this.applicationStore.user.userName;
            }
          } else if (this.orgPortcall.STATUS === 4 && this.portcall.STATUS !== 4) {
            // we are uncancelling a port call
          }
        }

        if (this.teamIsResponsible) {
          this.portcall.PERSON_IN_CHARGE = null;
        } else {
          this.portcall.TEAM_IN_CHARGE_ID = null;
        }

        // set isLoading to true to show progressbar if response is not immidiate
        this.isLoading = true;
        // api post...

        const sednaApi = new SednaApiService(this.portcall.SETUP_ID);
        const personInChargeChanged =
          this.portcall.PERSON_IN_CHARGE !== this.orgPortcall.PERSON_IN_CHARGE ||
          this.portcall.TEAM_IN_CHARGE_ID !== this.orgPortcall.TEAM_IN_CHARGE_ID;
        let followers = [];
        if (personInChargeChanged && sednaApi.isSednaIntegration) {
          followers = await apiGet(`sedna/followers/portcall/${this.portcall.ID}`);
        }

        const result = await apiPut('/portcall', {
          value: this.portcall,
          orgValue: this.orgPortcall,
          dynaValues: this.portcall.dynaValues,
          orgDynaValues: this.orgPortcall.dynaValues,
        });

        this.isLoading = false;
        if (result.resultCategory == 1) {
          if (sednaApi.isSednaIntegration && this.portcall.SEDNA_JOB_ID) {
            sednaApi.updateSednaJobIdInSedna('portcall', this.portcall.ID, this.portcall.SEDNA_JOB_ID, followers);
          }
          if (result.syncChanges) {
            if (result.syncChanges.sof) {
              this.portcallStore.clearSof();
            }
            if (result.syncChanges.berth) {
              getBerths(this.portcall.ID);
            }
          }
          getBerthedPortcalls(); // Update attendance berthed portcalls select input.
          if (this.isNew) {
            this.portcall = {};
            this.orgPortcall = {};
          } else {
            // Update store with new port call details...
            this.portcallStore.clearCall();
            getPortcallDetails(result.payload.ID);
          }
          this.applicationStore.setWhiteBoardDataIsDirty(true);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ais {
  width: calc(100% - 250px);
}

@media only screen and (max-width: 600px) {
  .ais {
    width: 100%;
  }
}

.vesselImg {
  position: relative;
  margin-top: -12px;
  min-width: 250px;
  max-width: 250px;
  min-height: 150px;
  max-height: 150px;
}

.border-status-berthed {
  border-left: 8px solid $colorStatusBerthed;
}

.border-status-anchored {
  border-left: 8px solid $colorStatusAnchored;
}

.border-status-expected {
  border-left: 8px solid $colorStatusExpected;
}

.border-status-sailed {
  border-left: 8px solid $colorStatusSailed;
}

.border-status-archived {
  border-left: 8px solid $colorStatusArchived;
}

.border-status-cancelled {
  border-left: 8px solid $colorStatusCancelled;
}

.initials-btn {
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 1;
  background: white;
}

.theme--dark {
  .initials-btn {
    background: #1e1e1e;
  }

  .border-status-berthed {
    border-left-color: $colorStatusBerthedDark;
  }

  .border-status-anchored {
    border-left-color: $colorStatusAnchoredDark;
  }

  .border-status-expected {
    border-left-color: $colorStatusExpectedDark;
  }

  .border-status-sailed {
    border-left-color: $colorStatusSailedDark;
  }

  .border-status-archived {
    border-left-color: $colorStatusArchivedDark;
  }

  .border-status-cancelled {
    border-left-color: $colorStatusCancelledDark;
  }
}
</style>
